import { isBrowser } from '.';

const get = (key) => {
  if (isBrowser()) {
    const value = window.localStorage.getItem(key);
    return value && JSON.parse(value);
  }
  return undefined;
};

const remove = (key) => {
  if (isBrowser()) {
    window.localStorage.removeItem(key);
  }
};

const set = (key, v) => {
  if (isBrowser()) {
    window.localStorage.setItem(key, JSON.stringify(v));
  }
};

export default {
  set,
  get,
  remove,
};
