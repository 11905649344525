import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconCopy } from '@frameio/vapor-icons'
import { Dialog, Button, Inline } from '@frameio/vapor';
import copy from 'copy-to-clipboard';

const ModalInnerDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 350px;
  min-width: 300px;
  margin: 20px;
`;

const ModalTitle = styled.h3`
  display: inline-block;
  margin-right: 10px;
`;

const ModalPre = styled.pre`
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 10px;
  border-radius: 6px;
  overflow: scroll;
`;

const copySecret = (value) => {
  copy(JSON.stringify(value, null, 2));
};

const ModalHeader = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
`;


const JSONDialog = ({ isShowing, onClose, data, title }) => (
  <Dialog
    style={{ background: 'white' }}
    isShowing={isShowing}
    onRequestClose={onClose}
  >
    <ModalInnerDiv>
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
        <Button onClick={() => copySecret(data)} iconAfter={<IconCopy />} color="primary" variant="contained" >Copy</Button>
      </ModalHeader>
      <ModalPre>{JSON.stringify(data, null, 2)}</ModalPre>
    </ModalInnerDiv>
  </Dialog>
);

JSONDialog.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default JSONDialog;
