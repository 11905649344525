import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { uniq, pull } from 'lodash';
import { Button, Checkbox, theme, Text } from '@frameio/vapor';
import Label from './label';

export const ErrorMessage = styled.div`
  color: ${theme.colors.palette.alertError};
  font-size: 12px;
  margin-top: 8px;
  text-align: left;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  button {
    padding-right: 8px;
  }
`;

const Container = styled.div``;

const StyledCheckBox = styled(Checkbox)`
  svg > {
    height: 8px;
  }
`;

const Group = styled.div`
  border-bottom: 1px solid rgb(227, 230, 236);
  margin: 10px 0;
  padding-bottom: 8px;

  h3 {
    font-size: 14px;
    margin: auto 0;
  }

  &:last-child {
    border: none;
  }
`;

const Scopes = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ScopeLabel = styled.label`
  max-width: 75%;
  line-height: 19px;
  font-size: 14px;
  color: #666a72;
  cursor: pointer;
`;

const Scope = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 8px;

  &:hover {
    cursor: pointer;
    background: rgb(241, 243, 246);
    border-radius: 6px;
  }

  div {
    border: 1px solid #d4d8e1;
  }
`;

const ScopeSelect = (props) => {
  const onChange = (scope) => {
    const { value } = props;

    if (value.includes(scope)) {
      props.onChange(pull(value, scope));
    } else {
      value.push(scope);
      props.onChange(value);
    }
  };

  const getScopes = (categoryType) => {
    const {
      options: { scopes },
    } = props;

    if (categoryType) {
      return scopes[categoryType].map(({ value }) => value);
    }

    return Object.values(scopes).reduce(
      (result, category) => [...result, ...category.map(({ value }) => value)],
      []
    );
  };

  const allSelected = (categoryType) => {
    const { value } = props;
    const options = getScopes(categoryType);

    return value.length > 0 && options.every((scope) => value.includes(scope));
  };

  const selectAll = (categoryType) => {
    const { value } = props;
    const update = getScopes(categoryType);

    if (!allSelected(categoryType)) {
      props.onChange(uniq([...value, ...update]));
    } else {
      props.onChange(value.filter((val) => update.indexOf(val) < 0));
    }
  };

  const { title, options, value, error, onBlur } = props;
  const { categories, scopes } = options;

  return (
    <>
      <Header>
        <Label>{title}</Label>
        <Button
          variant="link"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            selectAll();
          }}
        >
          {`${allSelected() ? 'Deselect' : 'Select'} all ${title}`}
        </Button>
      </Header>
      <Container error={error} onClick={onBlur}>
        {categories.map(
          ({ type, name }) =>
            scopes[type] && (
              <Group key={type}>
                <Header>
                  <Text color="#000000" style={{ fontWeight: 500 }}>
                    {name}
                  </Text>
                  {scopes[type].length > 1 && (
                    <Button
                      variant="link"
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        selectAll(type);
                      }}
                    >
                      {`${allSelected(type) ? 'Deselect' : 'Select'} all`}
                    </Button>
                  )}
                </Header>
                <Scopes>
                  {scopes[type].map((scope) => (
                    <Scope
                      key={scope.value}
                      onClick={(e) => {
                        e.preventDefault();
                        onChange(scope.value);
                      }}
                    >
                      <ScopeLabel htmlFor={scope.value}>
                        {scope.action}
                      </ScopeLabel>
                      <StyledCheckBox
                        checked={value.includes(scope.value)}
                        onChange={(e) => {
                          e.preventDefault();
                          onChange(scope.value);
                        }}
                      />
                    </Scope>
                  ))}
                </Scopes>
              </Group>
            )
        )}
      </Container>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};

ScopeSelect.propTypes = {
  title: PropTypes.string,
  options: PropTypes.shape({
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    // The scopes object can have any number of keys. Each key is an array of
    // scope objects. This custom validator tests that any value in the object
    // adheres to the expected array shape.
    scopes: PropTypes.objectOf((propValue, key, componentName, location) => {
      const validation = PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          action: PropTypes.string.isRequired,
        })
      );
      return PropTypes.checkPropTypes(
        { [key]: validation },
        propValue,
        location,
        componentName
      );
    }),
  }).isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  error: PropTypes.string,
};

ScopeSelect.defaultProps = {
  title: 'scopes',
  error: '',
  onBlur: () => {},
};

export const testExports = {
  Container,
  ErrorMessage,
  Button,
};

export default ScopeSelect;
