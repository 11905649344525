import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import { useId } from '@reach/auto-id';
import { Button } from '@frameio/vapor';
import {
  useForm,
  ScopeSelect,
  TeamSelect,
  TextField,
  Label,
  Fieldset,
} from '../form';
import { useAccounts, useAccountRoles } from '../../../api';

const defaultValues = { name: '', team: {}, url: '', events: [] };

const eventType = {
  Created: 'created',
  Copied: 'copied',
  Updated: 'updated',
  Deleted: 'deleted',
  Ready: 'ready',
  Versioned: 'versioned',
  Completed: 'completed',
  Uncompleted: 'uncompleted',
  Executed: 'executed',
};

const eventOptions = {
  categories: [
    {
      type: 'projects',
      name: 'Projects',
    },
    {
      type: 'assets',
      name: 'Assets',
    },
    {
      type: 'labels',
      name: 'Asset Labels',
    },
    {
      type: 'comments',
      name: 'Comments',
    },
    {
      type: 'reviewlinks',
      name: 'Review Links',
    },
    {
      type: 'collaborators',
      name: 'Collaborators',
    },
    {
      type: 'teammembers',
      name: 'Team Members',
    },
    {
      type: 'actions',
      name: 'Custom Actions',
    },
    {
      type: 'interactions',
      name: 'Interactions',
    },
  ],
  scopes: {
    projects: [
      {
        value: 'project.created',
        action: eventType.Created,
      },
      {
        value: 'project.updated',
        action: eventType.Updated,
      },
      {
        value: 'project.deleted',
        action: eventType.Deleted,
      },
    ],
    assets: [
      {
        value: 'asset.created',
        action: eventType.Created,
      },
      {
        value: 'asset.copied',
        action: eventType.Copied,
      },
      {
        value: 'asset.updated',
        action: eventType.Updated,
      },
      {
        value: 'asset.deleted',
        action: eventType.Deleted,
      },
      {
        value: 'asset.ready',
        action: eventType.Ready,
      },
      {
        value: 'asset.versioned',
        action: eventType.Versioned,
      },
    ],
    actions: [
      {
        value: 'action.executed',
        action: eventType.Executed,
      },
    ],
    interactions: [
      {
        value: 'interaction.executed',
        action: eventType.Executed,
      },
    ],
    labels: [
      {
        value: 'asset.label.updated',
        action: eventType.Updated,
      },
    ],
    comments: [
      {
        value: 'comment.created',
        action: eventType.Created,
      },
      {
        value: 'comment.updated',
        action: eventType.Updated,
      },
      {
        value: 'comment.deleted',
        action: eventType.Deleted,
      },
      {
        value: 'comment.completed',
        action: eventType.Completed,
      },
      {
        value: 'comment.uncompleted',
        action: eventType.Uncompleted,
      },
    ],
    reviewlinks: [
      {
        value: 'reviewlink.created',
        action: eventType.Created,
      },
    ],
    collaborators: [
      {
        value: 'collaborator.created',
        action: eventType.Created,
      },
      {
        value: 'collaborator.deleted',
        action: eventType.Deleted,
      },
    ],
    teammembers: [
      {
        value: 'teammember.created',
        action: eventType.Created,
      },
      {
        value: 'teammember.deleted',
        action: eventType.Deleted,
      },
    ],
  },
};

const WebhookForm = ({ onSubmit, editing, initialValues }) => {
  const { errors, values, onChange, onBlur } = useForm({
    ...defaultValues,
    ...initialValues,
  });

  const { data: accounts } = useAccounts();
  const { data: roles } = useAccountRoles(accounts?.map((d) => d.id));

  return (
    <form>
      <Fieldset>
        <Label htmlFor={useId('webhook-name')}>Name</Label>
        <TextField
          id="webhook-name"
          name="name"
          placeholder="My Awesome Webhook"
          value={values.name}
          onChange={(e) => onChange({ name: e.target.value })}
          onBlur={() => onBlur('name')}
          error={errors.name}
          data-webhook-name
        />
      </Fieldset>
      <Fieldset>
        <Label htmlFor={useId('webhook-url')}>URL</Label>
        <TextField
          id="webhook-url"
          name="url"
          placeholder="https://example.com/webhook"
          value={values.url}
          onChange={(e) => onChange({ url: e.target.value })}
          onBlur={() => onBlur('url')}
          error={errors.url}
          data-webhook-url
        />
      </Fieldset>
      <Fieldset>
        <Label htmlFor={useId('webhook-team')}>Team</Label>
        {accounts && roles ? (
          <TeamSelect
            id="webhook-team"
            roles={roles}
            accountIds={accounts.map((a) => a.id)}
            value={values.team}
            onChange={(team) => onChange({ team })}
            onBlur={() => onBlur('team')}
            error={errors.team}
            data-webhook-team
            disabled={editing}
          />
        ) : null}
      </Fieldset>
      <Fieldset>
        <ScopeSelect
          id="webhook-events"
          title="events"
          value={values.events}
          onChange={(events) => onChange({ events })}
          onBlur={() => onBlur('events')}
          error={errors.events}
          options={eventOptions}
          data-webhook-events
        />
      </Fieldset>
      <Fieldset>
        <Link
          to="/app/webhooks"
          style={{ marginRight: '10px', textDecoration: 'none' }}
        >
          <Button type="reset" variant="ghost" color="primary">
            Cancel
          </Button>
        </Link>
        <Button
          type="submit"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            return onSubmit({
              ...values,
              team: values.team.id,
            });
          }}
        >
          Submit
        </Button>
      </Fieldset>
    </form>
  );
};

WebhookForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  editing: PropTypes.bool,
  initialValues: PropTypes.object,
};

WebhookForm.defaultProps = {
  editing: false,
  initialValues: defaultValues,
};

export default WebhookForm;
