import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Spinner } from '@frameio/vapor';
import { useWebhookLogs } from '../../../api';
import Layout, { HorizontalWrapper } from '../../layout';
import Pagination from '../pagination';
import LogTable from '../log-table';
import JSONDialog from '../json-dialog';
import Breadcrumbs from '../breadcrumbs';
import { Listing } from '../listing';

const PaginationWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

const WebhookLogs = ({ webhookId }) => {
  const [page, setPage] = useState(1);
  const { data, isLoading, isFetching } = useWebhookLogs(webhookId, page);
  const [requestViewIndex, setRequestViewIndex] = useState(null);

  const logs = data?.webhookLogs || [];
  const pagination = data?.pagination;

  const dialogShowing = requestViewIndex !== null && !!logs.length;

  const path = `/app/webhooks/${webhookId}/logs`;

  return (
    <Layout>
      <HorizontalWrapper>
        <Listing className="webhook-logs">
          <Breadcrumbs
            currentPath={path}
            pages={[
              { path: '/app/webhooks', title: 'webhooks' },
              { path, title: 'logs' },
            ]}
          />
          <h2>Webhook Logs</h2>
          <LogTable
            data={logs}
            isLoading={isLoading}
            onRequestView={(index) => setRequestViewIndex(index)}
            hasResponse={false}
          />
          {!isLoading && pagination ? (
            <PaginationWrapper>
              <Pagination
                page={pagination.page}
                onPageChange={setPage}
                pages={pagination.pages}
                isLoading={isFetching && !isLoading}
              />
            </PaginationWrapper>
          ) : null}
          {dialogShowing && (
            <JSONDialog
              title="Request Body"
              isShowing={dialogShowing}
              onClose={() => setRequestViewIndex(null)}
              data={logs[requestViewIndex].request_body}
            />
          )}
          <Spinner size="small" isLoading={isLoading} />
        </Listing>
      </HorizontalWrapper>
    </Layout>
  );
};

WebhookLogs.propTypes = {
  webhookId: PropTypes.string.isRequired,
};

export default WebhookLogs;
