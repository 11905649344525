import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import styled from 'styled-components';
import { useId } from '@reach/auto-id';
import { Button, Checkbox } from '@frameio/vapor';
import { useAccounts, useAccountRoles } from '../../../api';
import { useForm, TeamSelect, Fieldset, TextField, Label } from '../form';

const CollaboratorAccess = styled.div`
  display: flex;

  div {
    border: 1px solid #d4d8e1;
  }

  span {
    color: rgb(32, 34, 43);
    padding-left: 10px;
    font-size: 13px;
  }
`;

const defaultValues = {
  name: '',
  description: '',
  event: '',
  url: '',
  team: {},
  allow_collaborators: false,
};

const CustomActionForm = ({ onSubmit, editing, initialValues }) => {
  const { errors, values, onChange, onBlur } = useForm({
    ...defaultValues,
    ...initialValues,
  });

  const { data: accounts } = useAccounts();
  const { data: roles } = useAccountRoles(accounts?.map((d) => d.id));

  return (
    <form>
      <Fieldset>
        <Label htmlFor={useId('custom-action-name')}>Name</Label>
        <TextField
          id="custom-action-name"
          name="name"
          placeholder="My Custom Action Name"
          value={values.name}
          onChange={(e) => onChange({ name: e.target.value })}
          onBlur={() => onBlur('name')}
          error={errors.name}
          data-custom-action-name
        />
      </Fieldset>
      <Fieldset>
        <Label htmlFor={useId('custom-action-description')}>Description</Label>
        <TextField
          id="custom-action-description"
          name="description"
          placeholder="My Custom Action Description"
          value={values.description}
          onChange={(e) => onChange({ description: e.target.value })}
          onBlur={() => onBlur('description')}
          error={errors.description}
          data-custom-action-description
        />
      </Fieldset>
      <Fieldset>
        <Label htmlFor={useId('custom-action-event')}>Event</Label>
        <TextField
          id="custom-action-event"
          name="event"
          placeholder="my.event"
          value={values.event}
          onChange={(e) => onChange({ event: e.target.value })}
          onBlur={() => onBlur('event')}
          error={errors.event}
          data-custom-action-event
        />
      </Fieldset>
      <Fieldset>
        <Label htmlFor={useId('custom-action-url')}>URL</Label>
        <TextField
          id="custom-action-url"
          name="url"
          placeholder="https://example.com/custom-action"
          value={values.url}
          onChange={(e) => onChange({ url: e.target.value })}
          onBlur={() => onBlur('url')}
          error={errors.url}
          data-custom-action-url
        />
      </Fieldset>
      <Fieldset>
        <Label htmlFor={useId('custom-action-team')}>Team</Label>
        {accounts && roles ? (
          <TeamSelect
            id="custom-action-team"
            roles={roles}
            teamId={values.team_id}
            accountIds={accounts.map((a) => a.id)}
            value={values.team}
            onChange={(team) => onChange({ team })}
            onBlur={() => onBlur('team')}
            error={errors.team}
            data-custom-action-team
            disabled={editing}
          />
        ) : null}
      </Fieldset>
      <Fieldset>
        <Label htmlFor={useId('custom-action-allow-collaborators')}>
          Access
        </Label>
        <CollaboratorAccess>
          <Checkbox
            checked={values.allow_collaborators}
            onChange={() =>
              onChange({ allow_collaborators: !values.allow_collaborators })
            }
          />
          <span>Allow collaborators to have access.</span>
        </CollaboratorAccess>
      </Fieldset>
      <Fieldset>
        <Link
          to="/app/custom-actions"
          style={{ marginRight: '10px', textDecoration: 'none' }}
        >
          <Button type="reset" variant="ghost" color="primary">
            Cancel
          </Button>
        </Link>
        <Button
          type="submit"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            return onSubmit({
              ...values,
              team: values.team.id,
            });
          }}
        >
          Submit
        </Button>
      </Fieldset>
    </form>
  );
};

CustomActionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  editing: PropTypes.bool,
  initialValues: PropTypes.object,
};

CustomActionForm.defaultProps = {
  editing: false,
  initialValues: defaultValues,
};

export default CustomActionForm;
