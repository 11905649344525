import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Select from 'react-select';
import { theme } from '@frameio/vapor';
import { useTeams } from '../../../api';
import { ErrorMessage } from './scope-select';

const Container = styled.div`
  .team-select__option {
    color: #666a72;
  }

  .team-select__option:hover,
  .team-select__option:focus,
  .team-select__option--is-focused,
  .team-select__option--is-selected {
    background-color: ${theme.colors.palette.brandPrimaryAlpha};
    color: ${theme.colors.palette.brandPrimary};
    border: none;
  }

  .team-select__option--is-selected {
    background-color: white;
  }
`;

const styles = (error) => ({
  container: (provided) => ({
    ...provided,
    borderColor: 'rgb(227, 230, 236)',
    fontSize: '14px',
  }),
  option: (provided) => ({
    ...provided,
  }),
  input: () => ({
    borderColor: 'rgb(227, 230, 236) !important',
    fontSize: '14px',
  }),
  control: (provided) => ({
    ...provided,
    cursor: 'pointer',
    boxShadow: null,
    borderColor: error ? '#E0475F' : 'rgb(227, 230, 236) !important',
    fontSize: '14px',
  }),
});

const Placeholder = styled.div`
  color: rgb(125, 130, 156);
  font-size: 13px;
  line-height: 1.5;
`;

const TeamSelect = ({
  onChange,
  error,
  onBlur,
  value: currentValue,
  accountIds,
  roles,
  disabled,
}) => {
  const { data = {}, isLoading } = useTeams(accountIds);

  const teams = Object.keys(data).reduce((opts, teamId) => {
    const team = data[teamId];
    const accountId = team.account_id;
    const { account: accountRole } = roles[accountId];

    const isTeamManager =
      team.user_role && team.user_role.team_role === 'team_manager';

    const isAccountManager = accountRole === 'admin' || accountRole === 'owner';

    if (isTeamManager || isAccountManager) {
      opts.push(team);
    }

    return opts;
  }, []);

  const options = teams.map((team) => ({
    value: team,
    label: team.name,
  }));

  let selected = options.find(({ value }) => value === currentValue) || '';

  if (disabled && currentValue) {
    selected = { value: currentValue, label: currentValue.name };
  }

  return (
    <Container>
      <Select
        placeholder={<Placeholder>Type to search...</Placeholder>}
        isSearchable
        isMulti={false}
        onChange={({ value }) => onChange(value)}
        options={options}
        value={selected}
        styles={styles(error)}
        onBlur={onBlur}
        classNamePrefix="team-select"
        isDisabled={disabled}
        isLoading={isLoading}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

TeamSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  onBlur: PropTypes.func,
  accountIds: PropTypes.array,
  roles: PropTypes.object,
  disabled: PropTypes.bool,
};

TeamSelect.defaultProps = {
  onBlur: () => {},
  error: '',
  accountIds: [],
  roles: {},
  disabled: false,
};

export default TeamSelect;
