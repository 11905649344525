import React from 'react';
import { navigate } from 'gatsby';
import CustomActionForm from './custom-action-form';
import Layout from '../../layout';
import { useCreateCustomAction } from '../../../api';
import Breadcrumbs from '../breadcrumbs';
import { FormContainer } from '../form';

const NewCustomAction = () => {
  const createCustomAction = useCreateCustomAction();
  return (
    <Layout>
      <FormContainer>
        <Breadcrumbs
          currentPath="/app/custom-actions/new"
          pages={[
            { path: '/app/custom-actions', title: 'custom actions' },
            { path: '/app/custom-actions/new', title: 'new' },
          ]}
        />
        <h2>Create a new custom action</h2>
        <CustomActionForm
          onSubmit={async (formData) => {
            const newCustomAction = await createCustomAction.mutateAsync(
              formData
            );
            navigate('/app/custom-actions', { state: { newCustomAction } });
          }}
        />
      </FormContainer>
    </Layout>
  );
};
export default NewCustomAction;
