import styled from 'styled-components';
import breakpoints, { sizes } from '../../../utils/breakpoints';

export { default as useForm } from './use-form';
export { default as Fieldset } from './fieldset';
export { default as Label } from './label';
export { default as ScopeSelect } from './scope-select';
export { default as TeamSelect } from './team-select';
export { TextField, SecretField } from './textfield';

export const FormContainer = styled.div`
  flex-direction: column;
  padding: 0px var(--page-margin);
  margin-top: 50px;
  ${breakpoints.sm} {
    width: ${sizes.sm}px;
    margin: 0 auto;
    margin-top: 30px;
  }

  h2 {
    margin: 30px 0;
  }
`;
