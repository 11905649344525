import { Link } from '@reach/router';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import styled from 'styled-components';
import { Button, Inline, Text, Spinner } from '@frameio/vapor';
import React, { useState } from 'react';
import {
  useAccounts,
  useCustomActions,
  useDeleteCustomAction,
} from '../../../api';
import Layout from '../../layout';
import SEO from '../../seo';
import ConfirmDelete from '../confirm-delete';
import {
  Listing,
  Links,
  Overview,
  Items,
  Item,
  ItemTitle,
  ItemHeader,
  ItemButtons,
  ItemEditButton,
  ItemTrashButton,
  Field,
  FieldKey,
  FieldValue,
} from '../listing';

import { formatDate } from '../../../utils';
import { SecretField } from '../form/textfield';

const Wrapper = styled.div({
  paddingLeft: 'var(--page-margin)',
  paddingRight: 'var(--page-margin)',
});

const CustomActions = ({ location }) => {
  const [secretCopied, setSecredCopied] = useState(false);

  const deleteCustomAction = useDeleteCustomAction();
  const [toDelete, setToDelete] = useState(null);

  const { data: accounts } = useAccounts();
  const { data: customActions, isIdle, isLoading } = useCustomActions(
    accounts?.map((acct) => acct.id)
  );

  const loading = isIdle || isLoading;

  const copySecret = (value) => {
    copy(value);
    setSecredCopied(value);
    setTimeout(() => setSecredCopied(false), 3000);
  };

  const getAccessCopy = (allowCollaborators) =>
    allowCollaborators
      ? 'Admins, team members, and collaborators'
      : 'Admins and team members only';

  const newCustomAction = location.state?.newCustomAction;

  return (
    <Layout>
      <SEO
        title="Frame.io - Developer Site"
        description="Learn more about building with the Frame.io API using Developer Tokens, Webhooks, Custom Actions, and OAuth apps. "
      />
      <Wrapper>
        <Listing>
          <h1>Custom Actions</h1>
          <Text variant="header" color="#000000">
            Create team-based Custom Actions.
            </Text>
          <Overview>
            <Text>
              Custom Actions are a way for you to build integrations directly
              into Frame.io as programmable UI components. This enables
              event-based workflows that can be triggered by users within the
              app, but controlled by an external system.
              </Text>
            <Links>
              <Link to="/app/custom-actions/new">
                <Button color="primary">Create a Custom Action</Button>
              </Link>
              <Link to="/app/custom-actions/builder">
                <Button variant="contained" color="primary">
                  Builder
                </Button>
              </Link>
              <Link to="/docs/custom-actions/custom-actions-overview">
                <Button
                  rel="noopener noreferrer"
                  target="_blank"
                  variant="ghost"
                  color="primary"
                >
                  See Docs
                  </Button>
              </Link>
            </Links>
          </Overview>
          <Items>
            {customActions?.map((c) => (
              <Item
                key={`custom-action-${c.id}`}
                className={
                  newCustomAction && newCustomAction.id === c.id && 'new-item'
                }
              >
                <ItemHeader>
                  <ItemTitle variant="header">{c.name}</ItemTitle>
                  <ItemButtons>
                    <ItemEditButton
                      path={`/app/custom-actions/${c.id}/edit`}
                    />
                    <ItemTrashButton onClick={() => setToDelete(c)} />
                  </ItemButtons>
                </ItemHeader>
                <Field>
                  <FieldKey>Team</FieldKey>
                  <FieldValue>{c.team.name}</FieldValue>
                </Field>
                <Field>
                  <FieldKey>Description</FieldKey>
                  <FieldValue>{c.description}</FieldValue>
                </Field>
                <Field>
                  <FieldKey>URL</FieldKey>
                  <FieldValue>{c.url}</FieldValue>
                </Field>
                <Field>
                  <FieldKey>Event</FieldKey>
                  <FieldValue>{c.event}</FieldValue>
                </Field>
                <Field>
                  <FieldKey>Access</FieldKey>
                  <FieldValue>
                    {getAccessCopy(c.allow_collaborators)}
                  </FieldValue>
                </Field>
                <Field>
                  <FieldKey>Secret</FieldKey>
                  <FieldValue>
                    <Inline gap={8}>
                      <SecretField size="small" value={c.webhook.secret} />
                      <Button
                        style={{ height: '26px' }}
                        variant="ghost"
                        color="primary"
                        size="small"
                        onClick={() => copySecret(c.webhook.secret)}
                      >
                        {secretCopied === c.webhook.secret
                          ? 'Copied!'
                          : 'Copy'}
                      </Button>
                    </Inline>
                  </FieldValue>
                </Field>
                <Field>
                  <FieldKey>Created</FieldKey>
                  <FieldValue>{formatDate(c.inserted_at)}</FieldValue>
                </Field>
                <Link
                  to={`/app/custom-actions/${c.id}/logs`}
                  style={{ textDecoration: 'none' }}
                >
                  <Button variant="link" color="primary">
                    View Logs
                    </Button>
                </Link>
              </Item>
            ))}
          </Items>
          {loading && <Spinner size="small" isLoading={loading} />}
        </Listing>
        {!!toDelete && (
          <ConfirmDelete
            isShowing={!!toDelete}
            title="Delete custom action?"
            name={toDelete.name}
            onCancel={() => setToDelete(null)}
            onConfirm={() => {
              deleteCustomAction
                .mutateAsync(toDelete.id)
                .then(() => setToDelete(null));
            }}
          />
        )}
      </Wrapper>
    </Layout>
  );
};
export default CustomActions;

CustomActions.propTypes = {
  location: PropTypes.object.isRequired,
};
