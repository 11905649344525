import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import { useId } from '@reach/auto-id';
import { Button } from '@frameio/vapor';
import { buildScopeOptions, buildScopeParams } from '../../../utils/scopes';
import { useForm, Fieldset, TextField, ScopeSelect, Label } from '../form';

const defaultValues = { scopes: [], description: '' };

const TokenForm = ({ editing, onSubmit, initialValues, isAdminOrOwner }) => {
  const scopeOptions = editing
    ? []
    : buildScopeOptions({
        forAccountOwnerOrAdmin: isAdminOrOwner,
        forUserToken: true,
      });

  const { errors, values, onChange, onBlur } = useForm({
    ...defaultValues,
    ...initialValues,
  });
  return (
    <form>
      <Fieldset>
        <Label htmlFor={useId('token-description')}>Description</Label>
        <TextField
          id="description-field"
          placeholder="My Awesome Token"
          value={values.description}
          onChange={(e) => onChange({ description: e.target.value })}
          onBlur={() => onBlur('description')}
          error={errors.description}
        />
      </Fieldset>
      {!editing && (
        <Fieldset>
          <ScopeSelect
            id="token-scopes"
            value={values.scopes}
            onChange={(scopes) => onChange({ scopes })}
            onBlur={() => onBlur('scopes')}
            options={scopeOptions}
            error={errors.scopes}
            data-token-scopes
          />
        </Fieldset>
      )}
      <Fieldset className="submit">
        <Link
          to="/app/tokens"
          style={{ marginRight: '10px', textDecoration: 'none' }}
        >
          <Button type="reset" variant="ghost" color="primary">
            Cancel
          </Button>
        </Link>
        <Button
          type="submit"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            return onSubmit({
              ...values,
              scopes: buildScopeParams(values.scopes),
            });
          }}
        >
          Submit
        </Button>
      </Fieldset>
    </form>
  );
};

export default TokenForm;

TokenForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  editing: PropTypes.bool,
  initialValues: PropTypes.object,
  isAdminOrOwner: PropTypes.bool.isRequired,
};

TokenForm.defaultProps = {
  editing: false,
  initialValues: defaultValues,
};
