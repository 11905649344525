import React from 'react';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import { useCustomAction, useUpdateCustomAction } from '../../../api';
import Layout from '../../layout';
import CustomActionForm from './custom-action-form';
import Breadcrumbs from '../breadcrumbs';
import { FormContainer } from '../form';

const EditCustomAction = ({ customActionId }) => {
  const { data } = useCustomAction(customActionId);
  const updateCustomAction = useUpdateCustomAction(customActionId);

  const path = `/app/custom-actions/${customActionId}/edit`;

  return (
    <Layout>
      <FormContainer>
        <Breadcrumbs
          currentPath={path}
          pages={[
            { path: '/app/custom-actions', title: 'custom actions' },
            { path, title: 'edit' },
          ]}
        />
        <h2>Edit Custom Action</h2>
        {data && (
          <CustomActionForm
            onSubmit={async (formData) => {
              await updateCustomAction.mutateAsync(formData);
              navigate('/app/custom-actions');
            }}
            initialValues={{
              ...pick(data, [
                'name',
                'description',
                'url',
                'event',
                'team',
                'allow_collaborators',
              ]),
            }}
            editing
          />
        )}
      </FormContainer>
    </Layout>
  );
};

export default EditCustomAction;

EditCustomAction.propTypes = {
  customActionId: PropTypes.string.isRequired,
};
