import React from 'react';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import { useWebhook, useUpdateWebhook } from '../../../api';
import Layout from '../../layout';
import WebhookForm from './webhook-form';
import Breadcrumbs from '../breadcrumbs';
import { FormContainer } from '../form';

const EditWebhook = ({ webhookId }) => {
  const { data } = useWebhook(webhookId);
  const updateWebhook = useUpdateWebhook(webhookId);

  const path = `/app/webhooks/${webhookId}/edit`;

  return (
    <Layout>
      <FormContainer>
        <Breadcrumbs
          currentPath={path}
          pages={[
            { path: '/app/webhooks', title: 'webhooks' },
            { path, title: 'edit' },
          ]}
        />
        <h2>Edit webhook</h2>
        {data && (
          <WebhookForm
            onSubmit={async (formData) => {
              await updateWebhook.mutateAsync(formData);
              navigate('/app/webhooks');
            }}
            initialValues={{
              ...pick(data, ['name', 'url', 'team', 'events']),
            }}
            editing
          />
        )}
      </FormContainer>
    </Layout>
  );
};

export default EditWebhook;

EditWebhook.propTypes = {
  webhookId: PropTypes.string.isRequired,
};
