import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from '@reach/router';
import { Button, IconButton } from '@frameio/vapor';
import { IconChevronRight } from '@frameio/vapor-icons';

const graphiteGray = '#707592';

export const BreadcrumbsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: -12px;
`;

const BreadcrumbLink = styled(Link)`
  text-decoration: none;
`;

const BreadcrumbButton = styled(Button)`
  font-weight: ${({ isActivePage }) => (isActivePage ? '500' : 'normal')};
  color: ${({ isActivePage }) =>
    isActivePage ? 'rgb(32, 34, 43)' : graphiteGray};
  background-color: white !important;
  text-transform: capitalize;
`;

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '-12px',
});

const Breadcrumbs = ({ currentPath, pages }) => (
  <Container>
    {pages.map(({ path, title }, index) => (
      <React.Fragment key={`breadcrumb-${path}`}>
        <BreadcrumbLink to={path}>
          <BreadcrumbButton
            size="medium"
            variant="ghost"
            color="primary"
            isActivePage={currentPath === path}
          >
            {title}
          </BreadcrumbButton>
        </BreadcrumbLink>
        {index < pages.length - 1 && (
          <IconButton
            variant="link"
            icon={<IconChevronRight size={16} color={graphiteGray} />}
          />
        )}
      </React.Fragment>
    ))}
  </Container>
);

Breadcrumbs.propTypes = {
  currentPath: PropTypes.string.isRequired,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Breadcrumbs;
