import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { Text } from '@frameio/vapor';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .pagination {
    width: fit-content;
    display: inline-block;
    padding-left: 0;
    border: 1px solid #d4d8e1;
    border-radius: 6px;
  }

  .pagination li {
    height: 32px;
    line-height: 32px;
    cursor: pointer;
    display: inline-block;
    width: 50px;
    border-right: 1px solid #d4d8e1;
    text-align: center;
    color: #666a72;

    & > a {
      width: 100%;
      height: 100%;
      display: block;
    }

    &.selected {
      background-color: #f1f3f6;
    }

    &.selected > a {
      color: #5b53ff;
    }

    &:last-child {
      border-right: none;
    }

    &:last-child a,
    &:first-child a {
      outline: none;
    }
  }
`;
const Pagination = ({ page = 1, pages, onPageChange, isLoading = false }) => (
  <Container>
    <ReactPaginate
      previousLabel="❮"
      nextLabel="❯"
      containerClassName="pagination"
      pageCount={pages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={({ selected }) => onPageChange(selected + 1)}
      initialPage={page - 1}
    />
    <div style={{ width: '100%', textAlign: 'center' }}>
      {isLoading && <Text color="#666a72">Loading...</Text>}
    </div>
  </Container>
);

export default Pagination;

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
