import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import { useOAuthApp, useUpdateOAuthApp } from '../../../api';
import Layout from '../../layout';
import OAuthAppForm from './oauth-app-form';
import { scopesToArray } from '../../../utils/scopes';
import Breadcrumbs from '../breadcrumbs';
import { FormContainer } from '../form';

const EditOAuthApp = ({ oauthAppId }) => {
  const { data } = useOAuthApp(oauthAppId);
  const updateOAuthApp = useUpdateOAuthApp(oauthAppId);

  const path = `/app/oauth-apps/${oauthAppId}/edit`;

  return (
    <Layout>
      <FormContainer>
        <Breadcrumbs
          currentPath={path}
          pages={[
            { path: '/app/oauth-apps', title: 'OAuth apps' },
            { path, title: 'edit' },
          ]}
        />
        <h2>Edit OAuth App</h2>
        {data && (
          <OAuthAppForm
            onSubmit={async (formData) => {
              await updateOAuthApp.mutateAsync(formData);
              navigate('/app/oauth-apps');
            }}
            initialValues={{
              name: data.name,
              redirect_uris: (data.redirect_uris || []).join(', '),
              scopes: scopesToArray(data.scopes),
              pkce: data.token_endpoint_auth_method === 'none',
            }}
            editing
            canTogglePKCE={!!data.client_secret}
          />
        )}
      </FormContainer>
    </Layout>
  );
};

export default EditOAuthApp;

EditOAuthApp.propTypes = {
  oauthAppId: PropTypes.string.isRequired,
};
