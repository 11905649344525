import { Link } from '@reach/router';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import { Button, Inline, Text, Spinner } from '@frameio/vapor';
import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import { useAccounts, useWebhooks, useDeleteWebhook } from '../../../api';
import Layout from '../../layout';
import SEO from '../../seo';

import {
  Events,
  Listing,
  Links,
  Overview,
  Items,
  Item,
  ItemTitle,
  ItemHeader,
  ItemButtons,
  ItemEditButton,
  ItemTrashButton,
  Field,
  FieldKey,
  FieldValue,
} from '../listing';
import ConfirmDelete from '../confirm-delete';

import { formatDate } from '../../../utils';
import { SecretField } from '../form/textfield';
import Pagination from '../pagination';

const PaginationWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

const Wrapper = styled.div({
  paddingLeft: 'var(--page-margin)',
  paddingRight: 'var(--page-margin)',
});

const Webhooks = ({ location }) => {
  const [page, setPage] = useState(1);
  const [secretCopied, setSecredCopied] = useState(false);
  const { data: accounts } = useAccounts();
  const { data, isIdle, isLoading, isFetching } = useWebhooks(
    accounts?.map((acct) => acct.id),
    page
  );
  const loading = isIdle || isLoading;
  const newWebhook = location.state?.newWebhook;

  const webhooks = data?.webhooks;
  const pagination = data?.pagination;

  const deleteWebhook = useDeleteWebhook();
  const [toDelete, setToDelete] = useState(null);

  const copySecret = (value) => {
    copy(value);
    setSecredCopied(value);
    setTimeout(() => setSecredCopied(false), 3000);
  };

  return (
    <>
      <SEO
        title="Frame.io - Developer - Webhooks"
        description="Learn more about building with the Frame.io API using Developer Tokens, Webhooks, Custom Actions, and OAuth apps. "
      />
      <Layout>
        <Wrapper>
          <Listing>
            <h1>Webhooks</h1>
            <Text variant="header" color="#000000">
              Create team-based webhooks.
            </Text>
            <Overview>
              <Text>
                Webhooks provide a way to leverage events that occur inside of
                Frame.io into notifications that can be sent to external systems
                for processing, API callback, and ultimately, workflow
                automation.
              </Text>
              <Links>
                <Link to="/app/webhooks/new">
                  <Button color="primary">Create a Webhook</Button>
                </Link>
                <Link to="/docs/automations-webhooks/webhooks-overview">
                  <Button
                    rel="noopener noreferrer"
                    target="_blank"
                    variant="ghost"
                    color="primary"
                  >
                    See Docs
                  </Button>
                </Link>
              </Links>
            </Overview>
            <Items>
              {webhooks?.map((w) => (
                <Item
                  key={`webhook-${w.id}`}
                  className={newWebhook && newWebhook.id === w.id && 'new-item'}
                >
                  <ItemHeader>
                    <ItemTitle variant="header">{w.name}</ItemTitle>
                    <ItemButtons>
                      <ItemEditButton path={`/app/webhooks/${w.id}/edit`} />
                      <ItemTrashButton onClick={() => setToDelete(w)} />
                    </ItemButtons>
                  </ItemHeader>
                  <Field>
                    <FieldKey>Team</FieldKey>
                    <FieldValue>{w.team.name}</FieldValue>
                  </Field>
                  <Field>
                    <FieldKey>URL</FieldKey>
                    <FieldValue>{w.url}</FieldValue>
                  </Field>
                  <Field>
                    <FieldKey>Events</FieldKey>
                    <FieldValue>
                      <Events events={w.events} />
                    </FieldValue>
                  </Field>
                  <Field>
                    <FieldKey>Secret</FieldKey>
                    <FieldValue>
                      <Inline gap={8}>
                        <SecretField
                          size="small"
                          value={w.secret}
                          onChange={noop}
                        />
                        <Button
                          style={{ height: '26px' }}
                          variant="ghost"
                          color="primary"
                          size="small"
                          onClick={() => copySecret(w.secret)}
                        >
                          {secretCopied === w.secret ? 'Copied!' : 'Copy'}
                        </Button>
                      </Inline>
                    </FieldValue>
                  </Field>
                  <Field>
                    <FieldKey>Created</FieldKey>
                    <FieldValue>{formatDate(w.inserted_at)}</FieldValue>
                  </Field>
                  <Link
                    to={`/app/webhooks/${w.id}/logs`}
                    style={{ textDecoration: 'none' }}
                  >
                    <Button variant="link" color="primary">
                      View Logs
                    </Button>
                  </Link>
                </Item>
              ))}
            </Items>
            {loading && <Spinner size="small" isLoading={loading} />}
            <PaginationWrapper>
              {pagination && (
                <Pagination
                  pages={pagination.pages || 1}
                  onPageChange={setPage}
                  page={page}
                  isLoading={isFetching && !isLoading}
                />
              )}
            </PaginationWrapper>
          </Listing>
          {!!toDelete && (
            <ConfirmDelete
              isShowing={!!toDelete}
              title="Delete webhook?"
              name={toDelete.name}
              onCancel={() => setToDelete(null)}
              onConfirm={() => {
                deleteWebhook
                  .mutateAsync(toDelete.id)
                  .then(() => setToDelete(null));
              }}
            />
          )}
        </Wrapper>
      </Layout>
    </>
  );
};

export default Webhooks;

Webhooks.propTypes = {
  location: PropTypes.object.isRequired,
};
