import { Button, Inline, Text } from '@frameio/vapor';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import React, { useState, Fragment } from 'react';
import { Link } from '@reach/router';
import styled from 'styled-components';
import { useTokens, useDeleteToken } from '../../../api';
import { SecretField } from '../form/textfield';
import Layout from '../../layout';
import SEO from '../../seo';
import {
  Listing,
  Overview,
  Links,
  Items,
  Item,
  ItemHeader,
  ItemTitle,
  ItemButtons,
  ItemEditButton,
  ItemTrashButton,
  Field,
  FieldKey,
  FieldValue,
  Scopes,
} from '../listing';
import { formatDate } from '../../../utils';
import ConfirmDelete from '../confirm-delete';

const Wrapper = styled.div({
  paddingLeft: 'var(--page-margin)',
  paddingRight: 'var(--page-margin)',
});

const Tokens = ({ location }) => {
  const { data: tokens } = useTokens();
  const [secretCopied, setSecredCopied] = useState(false);

  const deleteToken = useDeleteToken();
  const [toDelete, setToDelete] = useState(null);

  const newToken = location.state?.newToken;

  const copySecret = (value) => {
    copy(value);
    setSecredCopied(value);
    setTimeout(() => setSecredCopied(false), 3000);
  };

  return (
    <>
      <SEO
        title="Frame.io - Developer - Tokens"
        description="Learn more about building with the Frame.io API using Developer Tokens, Webhooks, Custom Actions, and OAuth apps. "
      />
      <Layout>
        <Wrapper>
          <Listing>
            <h1>Tokens</h1>
            <Text variant="header" color="#000000">
              Generate tokens to access the Frame.io API.
            </Text>
            <Overview>
              <Text>
                Frame.io supports external access through scoped API tokens.
                Tokens are associated with individual user accounts, and have
                the same access permissions as the user who created them. All
                API actions in Frame.io are restricted by scopes.
              </Text>
              <Links>
                <Link to="/app/tokens/new">
                  <Button color="primary">Create a Token</Button>
                </Link>

                <Link to="/docs/getting-started/authentication#developer-tokens">
                  <Button
                    rel="noopener noreferrer"
                    target="_blank"
                    variant="ghost"
                    color="primary"
                  >
                    See Docs
                  </Button>
                </Link>
              </Links>
            </Overview>
            <Items>
              {tokens?.map((t) => (
                <Item
                  key={`token-${t.id}`}
                  className={newToken && newToken.id === t.id && 'new-item'}
                >
                  <ItemHeader>
                    <ItemTitle variant="header">{t.description}</ItemTitle>
                    <ItemButtons>
                      <ItemEditButton path={`/app/tokens/${t.id}/edit`} />
                      <ItemTrashButton onClick={() => setToDelete(t)} />
                    </ItemButtons>
                  </ItemHeader>
                  {newToken && newToken.id === t.id ? (
                    <Field>
                      <FieldKey>Token</FieldKey>
                      <FieldValue>
                        <Inline gap={8}>
                          <SecretField size="small" value={newToken.token} />
                          <Button
                            style={{ height: '26px' }}
                            variant="ghost"
                            color="primary"
                            size="small"
                            onClick={() => copySecret(newToken.token)}
                          >
                            {secretCopied === newToken.token
                              ? 'Copied!'
                              : 'Copy'}
                          </Button>
                        </Inline>
                        <span>
                          Copy this now. This is the last time you will see it!
                        </span>
                      </FieldValue>
                    </Field>
                  ) : null}
                  <Field>
                    <FieldKey>Scope</FieldKey>
                    <FieldValue style={{ marginLeft: '16px' }}>
                      {t.scopes && <Scopes scopes={t.scopes} />}
                    </FieldValue>
                  </Field>
                  <Field>
                    <FieldKey>Created</FieldKey>
                    <FieldValue>{formatDate(t.inserted_at)}</FieldValue>
                  </Field>
                </Item>
              ))}
            </Items>
          </Listing>
          {!!toDelete && (
            <ConfirmDelete
              isShowing={!!toDelete}
              title="Delete token?"
              name={toDelete.description}
              onCancel={() => setToDelete(null)}
              onConfirm={() => {
                deleteToken
                  .mutateAsync(toDelete.id)
                  .then(() => setToDelete(null));
              }}
            />
          )}
        </Wrapper>
      </Layout>
    </>
  );
};

export default Tokens;

Tokens.propTypes = {
  location: PropTypes.object.isRequired,
};
