import styled from 'styled-components';

const Label = styled.label`
  font-size: 12px;
  line-height: 1.5;
  display: block;
  color: #494751;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: 400;
`;

export default Label;
