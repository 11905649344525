import React, { useMemo } from 'react';
import { navigate } from '@reach/router';
import { Spinner } from '@frameio/vapor';
import Layout from '../../layout';
import { FormContainer } from '../form';
import { useCreateToken, useAccounts, useAccountRoles } from '../../../api';
import TokenForm from './token-form';
import { userIsAdminOrOwner } from '../../../utils';
import Breadcrumbs from '../breadcrumbs';

const NewToken = () => {
  const createToken = useCreateToken();

  const { data: accounts } = useAccounts();
  const { data: roles, isIdle, isLoading } = useAccountRoles(
    accounts?.map((d) => d.id)
  );

  const isAdminOrOwner = useMemo(() => roles && userIsAdminOrOwner(roles), [
    roles,
  ]);

  const loading = isIdle || isLoading;

  return (
    <Layout>
      <FormContainer>
        <Breadcrumbs
          currentPath="/app/tokens/new"
          pages={[
            { path: '/app/tokens', title: 'tokens' },
            { path: '/app/tokens/new', title: 'new' },
          ]}
        />
        <h2>Create a new API Token</h2>
        {isAdminOrOwner !== undefined && (
          <TokenForm
            isAdminOrOwner={isAdminOrOwner}
            onSubmit={async (formData) => {
              const newToken = await createToken.mutateAsync(formData);
              navigate('/app/tokens', { state: { newToken } });
            }}
          />
        )}
      </FormContainer>
      {loading && <Spinner size="small" isLoading={loading} />}
    </Layout>
  );
};

export default NewToken;
