import React, { useState, useRef } from 'react';
import Editor from "@monaco-editor/react";
import styled from 'styled-components';
import Breadcrumbs from '../../breadcrumbs';
import { Box } from '@frameio/vapor';
import { breakpoints } from '@frameio/developer-components';
import Layout, { HorizontalWrapper } from '../../../layout';
import { Listing } from '../../listing';
import CustomActionPreview from './custom-action-preview';
// import copy from 'copy-to-clipboard';

import DefaultCustomActionSettings from './ca-defaults.json';
import jsonSchema from './ca-schema.json';

export const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 8px;
  text-align: left;
`;

const Preview = styled.div`
  display: flex;
  align: right;
  justify-content: center;
  & > div {
    overflow: scroll;
    border-radius: 8px;
    border: 1px solid ${({ error }) => (error ? 'gray' : 'purple')};
  }
`;

const Code = styled.div`
  height: 70vh;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid ${({ error }) => (error ? 'red' : 'purple')};
`;

const Builder = styled.div({
  display: 'grid',
  gridGap: '24px',
  maxWidth: '1200px',
  minHeight: '500px !important',
  maxHeight: '450px',
  gridTemplateColumns: '1fr',
  [breakpoints.md]: {
    gridTemplateColumns: '2fr 1fr',
    gridTemplateRows: '70vh'
  },
});

// const RoundedBox = styled(Box)({
//   borderRadius: "8px",
//   border: `1px solid ${({ error }) => (error ? 'red' : 'purple')}`,
// })

const StyledEditor = styled(Editor)({
  span: {
    fontFamily: 'Menlo, Monaco, monospace !important',
  }
})


const CustomActionBuilder = () => {
  // For breadcrumbs
  const path = `/ app / custom - actions / builder`;

  // Builder state
  const [state, setState] = useState({
    code: JSON.stringify(DefaultCustomActionSettings, null, '\t'),
    preview: DefaultCustomActionSettings,
    error: null,
  });

  // Additional state management
  const [isEditorReady, setIsEditorReady] = useState(false);
  const [languageModel, setLanguageModel] = useState(false);

  const editorRef = useRef(null);

  const { code, preview, error } = state;

  function handleEditorDidMount(editor, monaco) {
    // Use this handler to save the editor reference
    setIsEditorReady(true);
    editorRef.current = editor;

    console.log("Editor mounted")

    // console.log("Remeasuring fonts...")
    // monaco.editor.remeasureFonts();

    console.log("Setting language model")
    const model = editor.getModel();
    model.updateOptions({ tabSize: 4, insertSpaces: false, insertTabs: true })
    setLanguageModel(model);
  }

  function createDependencyProposals(range) {
    // returning a static list of proposals, not even looking at the prefix (filtering is done by the Monaco editor),
    // here you could do a server side lookup
    return [
      {
        label: '"Text"',
        kind: monaco.languages.CompletionItemKind.Snippet,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: "A simple text field with no additional parameters.",
        insertText: JSON.stringify({
          type: "text",
          label: "Title",
          name: "title",
          value: "Myvideo.mp4"
        }),
        range: range
      },
      {
        label: '"Text Area"',
        kind: monaco.languages.CompletionItemKind.Snippet,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: "A simple text area with no additional parameters.",
        insertText: JSON.stringify({
          type: "textarea",
          label: "Description",
          name: "description",
          value: "This video is really, really popular."
        }),
        range: range
      },
      {
        label: '"Select List"',
        kind: monaco.languages.CompletionItemKind.Snippet,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: "A dropdown that you can supply single-select options to.",
        insertText: JSON.stringify({
          type: "select",
          label: "Captions",
          name: "captions",
          value: "off",
          options: [
            {
              name: "Off",
              value: "off"
            },
            {
              name: "On",
              value: "on"
            }
          ]
        }),
        range: range
      }
    ];
  }

  function handleEditorWillMount(monaco) {
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      schemas: [
        {
          uri: 'internal://server/ca-schema.json',
          schema: JSON.stringify(jsonSchema)
        }
      ]
    })

    monaco.languages.registerCompletionItemProvider('json', {
      provideCompletionItems: function (position) {
        // find out if we are completing a property in the 'dependencies' object.
        var textUntilPosition = languageModel.getValueInRange({ startLineNumber: 1, startColumn: 1, endLineNumber: position.lineNumber, endColumn: position.column });
        var match = textUntilPosition.match(/"fields"\s*:\s*\{\s*("[^"]*"\s*:\s*"[^"]*"\s*,\s*)*([^"]*)?$/);
        if (!match) {
          return { suggestions: [] };
        }
        var word = languageModel.getWordUntilPosition(position);
        var range = {
          startLineNumber: position.lineNumber,
          endLineNumber: position.lineNumber,
          startColumn: word.startColumn,
          endColumn: word.endColumn
        };
        return {
          suggestions: createDependencyProposals(range)
        };
      }
    });
  };

  function testSchema(value) {
    let p;
    let err;
    try {
      p = JSON.parse(value);
    } catch (e) {
      err = e;
    }
    return {
      p,
      err
    }
  }

  function handleEditorValidation(markers) {
    // model markers
    markers.forEach(marker => {
      console.log("onValidate:", marker.message)
      // setState({ error: marker.message });
    });
  }

  // Monao editor configuration options
  const editorOptions = {
    minimap: {
      enabled: false
    },
    autoindent: true,
    automaticLayout: true,
    fontSize: "12px",
    tabSize: 4,
    acceptSuggestionOnCommitCharacter: true,
    acceptSuggestionOnEnter: "on",
    codeLens: true,
    colorDecorators: true,
    contextmenu: true,
    cursorBlinking: "blink",
    wordWrap: "wordWrapColumn",
    wordWrapColumn: 120,
    wordWrapMinified: true,
    wrappingIndent: "indent",
    scrollBeyondLastLine: false,
    renderWhitespace: true,
  }

  return (
    <Layout>
      <HorizontalWrapper>
        <Listing className="custom-action-builder">
          <Breadcrumbs
            currentPath={path}
            pages={[
              { path: '/app/custom-actions', title: 'custom actions' },
              { path, title: 'builder' },
            ]}
          />
          <div>
          </div>
          <Builder>
            <Box>
              <h2>Code</h2>
              {error && (
                <ErrorMessage>
                  Whoops! Looks like that is some invalid JSON. Preview will update
                  once fixed.
                </ErrorMessage>
              )}
              <Code error={error}>
                <StyledEditor
                  loading={"Loading..."}
                  options={editorOptions}
                  language="json"
                  value={code}
                  defaultValue={JSON.stringify(DefaultCustomActionSettings, null, '\t')}
                  loading={"Loading..."}
                  options={editorOptions}
                  beforeMount={handleEditorWillMount}
                  onMount={handleEditorDidMount}
                  onValidate={handleEditorValidation}
                  onChange={(value) => {
                    const { p, err } = testSchema(value)
                    if (p) {
                      setState({ preview: p, error: null, code: value });
                    } else {
                      setState({ preview, error: err, code: value });
                    }
                  }}
                />
              </Code>
            </Box>
            <Box>
              <h2>Preview</h2>
              <Preview error={error}>
                <CustomActionPreview {...preview} />
              </Preview>
            </Box>
          </Builder>
        </Listing>
      </HorizontalWrapper>
    </Layout>
  );
};

export default CustomActionBuilder;
