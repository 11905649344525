import React from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';
import { Button, Text, theme } from '@frameio/vapor';
import styled from 'styled-components';
import { timeDiff, formatDateAccurate } from '../../utils';

const Table = styled.table`
  table-layout: fixed;
  width: 100%;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0 10px;
  white-space: nowrap;
`;

const Status = styled.span`
  background-color: ${theme.colors.palette.alertSuccess500};
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-right: 8px;

  &.bad {
    background-color: ${theme.colors.palette.alertError500};
  }
`;

const StatusCode = ({ code }) => (
  <>
    <Status className={code >= 400 ? 'bad' : 'good'} />
    {code}
  </>
);

const LogTable = ({
  data,
  isLoading,
  onRequestView,
  onResponseView,
  hasResponse = true,
  emptyMessage = 'No webhook logs found.',
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Event Type',
        accessor: 'type',
      },
      {
        Header: 'Status Code',
        accessor: 'response_status',
        Cell: (cell) => <StatusCode code={cell.value} />,
      },
      {
        Header: 'Request Body',
        accessor: 'request_body',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { index } }) => (
          <Button
            variant="link"
            size="small"
            color="primary"
            onClick={() => onRequestView(index)}
          >
            View details
          </Button>
        ),
      },
      {
        Header: 'Response Body',
        accessor: 'response_body',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { index } }) => (
          <Button
            variant="link"
            size="small"
            color="primary"
            onClick={() => onResponseView(index)}
          >
            View details
          </Button>
        ),
      },
      {
        Header: 'Retries',
        accessor: 'retries',
      },
      {
        Header: 'Inserted At',
        accessor: 'inserted_at',
        Cell: (cell) => formatDateAccurate(cell.value),
      },
      {
        Header: 'Latency',
        accessor: (row) => timeDiff(row.updated_at, row.inserted_at),
        Cell: ({ value }) => `${value} ms`,
      },
    ],
    [onRequestView, onResponseView]
  );

  const initialState = !hasResponse ? { hiddenColumns: ['response_body'] } : {};

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data, initialState });

  return (
    <>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} style={{ padding: 0 }}>
                  <Text color="#000000" style={{ fontWeight: '500' }}>
                    {column.render('Header')}
                  </Text>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {!isLoading && data.length ? (
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>
                      <Text color="#000000" style={{ margin: '2.5px 0' }}>
                        {cell.render('Cell')}
                      </Text>
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        ) : null}
      </Table>
      {!isLoading && !data.length ? (
        <Text color="#000000">{emptyMessage}</Text>
      ) : null}
    </>
  );
};

LogTable.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onRequestView: PropTypes.func.isRequired,
  onResponseView: PropTypes.func,
  hasResponse: PropTypes.bool,
  emptyMessage: PropTypes.string,
};

LogTable.defaultProps = {
  onResponseView: noop,
  hasResponse: true,
  emptyMessage: 'No webhook logs found.',
};

StatusCode.propTypes = {
  code: PropTypes.number.isRequired,
};

export default LogTable;
