import axios from 'axios';
import { flatten, orderBy } from 'lodash';
import qs from 'query-string';
import { useQuery, QueryClient, useMutation } from 'react-query';
import {
  getAuthToken,
  getRefreshToken,
  setAuthToken,
  hasAuthTokenExpired,
} from '@frameio/developer-components';

export const queryClient = new QueryClient();

export const instance = axios.create({
  baseURL: process.env.GATSBY_API_V2_HOST,
});

instance.interceptors.request.use(
  (config) => {
    const token = getAuthToken();

    if (token) {
      config.headers.Authorization = token;  // eslint-disable-line
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const refreshAccessToken = (refreshToken) => {
  const path = `${
    process.env.GATSBY_API_V2_HOST
  }/auth/refresh_tokens/${`${refreshToken}`}/apply`;

  return axios.post(path, {}).then(({ headers }) => {
    const token = headers.authorization;
    setAuthToken(token);
    return token;
  });
};
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if ([403, 401].includes(error.response.status) && !originalRequest._retry) { // eslint-disable-line
      const refreshToken = getRefreshToken();
      const authToken = getAuthToken();
      const hasExpired = hasAuthTokenExpired(authToken);
      originalRequest._retry = true; // eslint-disable-line

      if (!refreshToken || !authToken || !hasExpired) {
        window.location.reload();
      }

      let token;

      try {
        token = await refreshAccessToken(refreshToken);
      } catch {
        window.location.reload();
      }

      originalRequest.headers.Authorization = token;
      return instance(originalRequest);
    }

    return Promise.reject(error);
  }
);

export const useCurrentUser = (opts = null) =>
  useQuery('currentUser', () => instance.get('/me').then(({ data }) => data), {
    retry: false,
    ...opts,
  });

export const useTokens = () =>
  useQuery(
    'tokens',
    () =>
      instance
        .get('/tokens?sort=inserted_at')
        .then(({ data }) => orderBy(data, ['inserted_at'], ['desc'])),
    {
      onSuccess: (tokens) =>
        tokens.forEach((token) => {
          queryClient.setQueryData(['tokens', token.id], token);
        }),
    }
  );

export const useCreateToken = () =>
  useMutation(
    (createTokenData) =>
      instance.post('/tokens', createTokenData).then(({ data }) => data),
    {
      onSuccess: (token) => {
        queryClient.setQueryData('tokens', (tokens) => [token, ...tokens]);
      },
    }
  );

export const useToken = (tokenId) =>
  useQuery(['tokens', tokenId], () =>
    instance.get(`/tokens/${tokenId}`).then(({ data }) => data)
  );

export const useUpdateToken = (tokenId) =>
  useMutation(
    (tokenData) =>
      instance.patch(`/tokens/${tokenId}`, tokenData).then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tokens');
      },
    }
  );

export const useAccounts = () =>
  useQuery('accounts', () =>
    instance.get('/accounts').then(({ data }) => data)
  );

const getTeamsForAccount = (accountId) => {
  const params = qs.stringify({ include: 'user_role', page_size: 1000 });
  return instance.get(`/accounts/${accountId}/teams?${params}`);
};

export const useTeams = (accountIds) =>
  useQuery(
    ['teams', accountIds],
    () =>
      Promise.all(accountIds.map(getTeamsForAccount)).then((responses) =>
        responses.reduce(
          (teams, { data }) => ({
            ...teams,
            ...data.reduce((acc, team) => ({ ...acc, [team.id]: team }), {}),
          }),
          {}
        )
      ),
    {
      enabled: !!accountIds,
    }
  );

export const useAccountRoles = (accountIds) =>
  useQuery(
    ['roles', { accountIds }],
    () =>
      Promise.all(
        accountIds.map((id) => instance.get(`/accounts/${id}/membership`))
      ).then((resps) =>
        resps.reduce((a, { data }, i) => ({ ...a, [accountIds[i]]: data }), {})
      ),
    {
      enabled: !!accountIds,
    }
  );

export const useWebhooks = (accountIds, page) =>
  useQuery(
    ['webhooks', accountIds, page],
    () => {
      const params = qs.stringify({ include: 'team', page, page_size: 10 });
      const requests = accountIds.map((accountId) =>
        instance.get(`/accounts/${accountId}/hooks?${params}`)
      );
      return Promise.all(requests).then((responses) => {
        let webhooks = [];
        let pages = 0;
        responses.forEach(({ data, headers }) => {
          webhooks = [...webhooks, ...data];
          pages = Math.max(pages, parseInt(headers['total-pages'], 10));
        });
        return { webhooks, pagination: { pages, page } };
      });
    },
    {
      keepPreviousData: true,
      enabled: !!(accountIds && page),
      onSuccess: ({ webhooks }) => {
        webhooks.forEach((webhook) =>
          queryClient.setQueryData(['webhooks', webhook.id], webhook)
        );
      },
    }
  );

export const useCreateWebhook = () =>
  useMutation(
    (webhookData) => {
      const path = `/teams/${webhookData.team}/hooks`;
      return instance.post(path, webhookData).then(({ data }) => data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('webhooks');
      },
    }
  );

export const useUpdateWebhook = (webhookId) =>
  useMutation(
    (webhookData) => {
      const path = `/hooks/${webhookId}`;
      return instance.patch(path, webhookData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('webhooks');
      },
    }
  );

export const useWebhook = (webhookId) =>
  useQuery(['webhooks', webhookId], () =>
    instance.get(`/hooks/${webhookId}`).then(async (resp) => {
      const webhook = resp.data;
      const { data: team } = await instance.get(`/teams/${webhook.team_id}`);
      return { ...webhook, team };
    })
  );

export const getCustomActionsForAccount = (accountId) =>
  instance
    .get(`/accounts/${accountId}/actions`, {
      params: { include: 'team,webhook', context: 'platform' },
    })
    .then(({ data }) => data);

export const useCustomActions = (accountIds) =>
  useQuery(
    ['custom-actions', accountIds],
    () =>
      Promise.all(accountIds.map(getCustomActionsForAccount))
        .then(flatten)
        .then((data) => orderBy(data, ['inserted_at'], ['desc'])),
    {
      enabled: !!accountIds,
      onSuccess: (customActions) => {
        customActions.forEach((customAction) => {
          queryClient.setQueryData(
            ['custom-actions', customAction.id],
            customAction
          );
        });
      },
    }
  );

export const useCustomAction = (actionId) =>
  useQuery(['custom-actions', actionId], () =>
    instance.get(`/actions/${actionId}`).then(async (resp) => {
      const action = resp.data;
      const { data: team } = await instance.get(`/teams/${action.team_id}`);
      return { ...action, team };
    })
  );

export const useCreateCustomAction = () =>
  useMutation(
    ({ team: teamId, ...actionData }) =>
      instance
        .post(`/teams/${teamId}/actions`, {
          ...actionData,
          include: 'team,webhook',
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('custom-actions');
      },
    }
  );

export const useUpdateCustomAction = (customActionId) =>
  useMutation(
    ({ team: teamId, ...actionData }) =>
      instance
        .patch(`/actions/${customActionId}`, {
          ...actionData,
          include: 'team,webhook',
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => queryClient.invalidateQueries('custom-actions'),
    }
  );

export const useOAuthApps = () =>
  useQuery(
    'oauth-apps',
    () => instance.get('/oauth_apps').then((r) => r.data),
    {
      onSuccess: (apps) => {
        apps.forEach((app) => {
          queryClient.setQueryData(['oauth-apps', app.id], app);
        });
      },
    }
  );

export const useCreateOAuthApp = () =>
  useMutation(
    (oauthData) =>
      instance.post('/oauth_apps', oauthData).then((resp) => resp.data),
    {
      onSuccess: (app) => {
        queryClient.setQueryData('oauth-apps', (apps) => [app, ...apps]);
      },
    }
  );

export const useOAuthApp = (oauthAppId) =>
  useQuery(['oauth-apps', oauthAppId], () =>
    instance.get(`/oauth_apps/${oauthAppId}`).then((r) => r.data)
  );

export const useUpdateOAuthApp = (oauthAppId) =>
  useMutation(
    (oauthData) =>
      instance
        .patch(`/oauth_apps/${oauthAppId}`, oauthData)
        .then((r) => r.data),
    {
      onSuccess: () => queryClient.invalidateQueries('oauth-apps'),
    }
  );

export const useWebhookLogs = (webhookId, page = 1) =>
  useQuery(
    ['webhook-logs', webhookId, page],
    () => {
      const params = qs.stringify({ page, page_size: 20 });
      const path = `/hooks/${webhookId}/logs?${params}`;
      return instance.get(path).then(({ data: webhookLogs, headers }) => ({
        webhookLogs,
        pagination: {
          pages: parseInt(headers['total-pages'], 10),
          page,
        },
      }));
    },
    { keepPreviousData: true }
  );

export const useCustomActionLogs = (customActionId, page = 1) =>
  useQuery(
    ['custom-action-logs', customActionId, page],
    () => {
      const params = qs.stringify({ page, page_size: 20 });
      const path = `/actions/${customActionId}/logs?${params}`;
      return instance.get(path).then(({ data: customActionLogs, headers }) => ({
        customActionLogs,
        pagination: {
          pages: parseInt(headers['total-pages'], 10),
          page,
        },
      }));
    },
    { keepPreviousData: true }
  );

export const useDeleteCustomAction = () =>
  useMutation(
    (customActionId) => instance.delete(`/actions/${customActionId}`),
    {
      onSuccess: () => queryClient.invalidateQueries('custom-actions'),
    }
  );

export const useDeleteToken = () =>
  useMutation((tokenId) => instance.delete(`/tokens/${tokenId}`), {
    onSuccess: () => queryClient.invalidateQueries('tokens'),
  });

export const useDeleteWebhook = () =>
  useMutation((webhookId) => instance.delete(`/hooks/${webhookId}`), {
    onSuccess: () => queryClient.invalidateQueries('webhooks'),
  });

export const useDeleteOAuthApp = () =>
  useMutation((oauthAppId) => instance.delete(`/oauth_apps/${oauthAppId}`), {
    onSuccess: () => queryClient.invalidateQueries('oauth-apps'),
  });
