import { isArray } from 'lodash';
import { useState, useMemo } from 'react';

const useForm = (initValues = {}) => {
  const [values, setValues] = useState(initValues);

  const [dirty, setDirty] = useState({});

  const isBlank = (value) => (isArray(value) ? !value.length : !value);

  const errors = useMemo(
    () =>
      Object.entries(values).reduce(
        (acc, [name]) => ({
          ...acc,
          [name]:
            dirty[name] && isBlank(values[name])
              ? `${name} should not be blank`
              : '',
        }),
        {}
      ),
    [dirty, values]
  );

  return {
    values,
    errors,
    onChange: (updates) => setValues({ ...values, ...updates }),
    onBlur: (name) => !dirty[name] && setDirty({ ...dirty, [name]: true }),
  };
};

export default useForm;
