import React from 'react';
import { Link } from '@reach/router';
import { theme, IconButton, Chip, Text } from '@frameio/vapor';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconEditFill, IconTrashFill, IconAppLogs } from '@frameio/vapor-icons';
import { sizes } from '../../utils/breakpoints';

export const Listing = styled.div`
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  h1 {
    color: #000000;
  }
`;

export const Overview = styled.div`
  span {
    color: #666a72;
  }
  margin: 25px 0;
  max-width: 1000px;
`;

export const Links = styled.div`
  margin: 20px 0;
  button:first-child {
    margin-right: 15px;
  }

  a {
    text-decoration: none;
  }
`;

export const Items = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: ${sizes.lg}px;
`;

export const Item = styled.li`
  margin: 10px 0px;
  border-bottom: 1px solid ${theme.colors.palette.tintA80};
  padding: 10px 0px;
  &.new-item {
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px rgba(91, 83, 255, 0.9),
      0 0 0 2px rgba(91, 83, 255, 0.25);
  }
`;

export const ItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  margin-bottom: 10px;
`;

export const ItemTitle = styled(Text)`
  font-weight: 500;
  flex-grow: 1;
  color: rgb(15, 16, 20);
`;

export const ItemButtons = styled.div`
  a + button {
    margin-left: 16px;
  }
`;

export const ItemEditButton = ({ path }) => (
  <Link to={path}>
    <IconButton
      color="primary"
      variant="ghost"
      icon={<IconEditFill />}
      label="Edit"
    />
  </Link>
);

export const ItemLogButton = ({ path }) => (
  <Link to={path}>
    <IconButton
      color="primary"
      variant="ghost"
      icon={<IconAppLogs />}
      label="Logs"
    />
  </Link>
);

ItemLogButton.propTypes = {
  path: PropTypes.string.isRequired,
};

ItemEditButton.propTypes = {
  path: PropTypes.string.isRequired,
};

export const ItemTrashButton = ({ onClick }) => (
  <IconButton
    variant="ghost"
    color="primary"
    icon={<IconTrashFill />}
    onClick={onClick}
    label="Delete"
  />
);

ItemTrashButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export const FieldKey = styled(Text)`
  font-size: 13px;
  min-width: 85px;
  color: #666a72;
`;

export const Field = styled.div`
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  margin-bottom: 10px;
  ${FieldKey} {
    font-weight: 500;
  }
`;

export const FieldValue = styled(Text)`
  color: #666a72;
  flex: 1;
  font-size: 14px;
  margin: 0 24px;

  a {
    text-decoration: none;
  }
`;

const Scope = styled(Chip)`
  background: none;
  color: unset;

  :hover {
    color: unset;
    background-color: rgba(246, 246, 248, 0.9);
  }
`;

export function getScopeLabels(scopes) {
  return Object.keys(scopes)
    .filter((scope) => scope !== 'id' && scopes[scope])
    .map((scope) => scope.replace('_', '.'));
}

export const Scopes = ({ scopes }) =>
  getScopeLabels(scopes).map((label) => (
    <Scope key={label} animate={{ opacity: '1', scale: '1' }}>
      {label}
    </Scope>
  ));

export const Events = ({ events }) =>
  events.map((e) => <Scope key={e}>{e}</Scope>);
