import React, { useMemo } from 'react';
import { navigate } from '@reach/router';
import Layout from '../../layout';
import { useCreateOAuthApp, useAccounts, useAccountRoles } from '../../../api';
import OAuthAppsForm from './oauth-app-form';
import { userIsAdminOrOwner } from '../../../utils';
import Breadcrumbs from '../breadcrumbs';
import { FormContainer } from '../form';

const NewOAuthApp = () => {
  const { data: accounts } = useAccounts();
  const { data: roles } = useAccountRoles(accounts?.map((d) => d.id));

  const isAdminOrOwner = useMemo(() => roles && userIsAdminOrOwner(roles), [
    roles,
  ]);

  const createApp = useCreateOAuthApp();

  return (
    <Layout>
      <FormContainer>
        <Breadcrumbs
          currentPath="/app/oauth-apps/new"
          pages={[
            { path: '/app/oauth-apps', title: 'OAuth apps' },
            { path: '/app/oauth-apps/new', title: 'new' },
          ]}
        />
        <h2>Create a new OAuth App.</h2>
        {isAdminOrOwner !== undefined && (
          <OAuthAppsForm
            onSubmit={async (formData) => {
              const newOAuthApp = await createApp.mutateAsync(formData);
              navigate('/app/oauth-apps', { state: { newOAuthApp } });
            }}
            isAdminOrOwner={isAdminOrOwner}
            canTogglePKCE
          />
        )}
      </FormContainer>
    </Layout>
  );
};

export default NewOAuthApp;
