import React from 'react';

export function ErrorFallback() {
    const resetErrorBoundary = () => {
        window.location.reload()
        console.log("Resetting app state")
    }

    return (
        <div role="alert">
            <p>Something went wrong :(</p>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    )
}
