import React from 'react';
import PropTypes from 'prop-types';
import { redirectToAuthPortal } from '@frameio/developer-components';
import { useAuthContext } from '../../context/auth';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user } = useAuthContext();

  if (user) {
    return <Component {...rest} />;
  }

  redirectToAuthPortal();
  return null;
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PrivateRoute;
