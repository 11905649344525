import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { IconCogFill, IconChevronDown } from '@frameio/vapor-icons';
import {
  TextareaField,
  Inline,
  TextField,
  Select,
  MenuRadio,
  Box,
  Text,
  Button,
  theme,
} from '@frameio/vapor';

const FormGroup = styled.fieldset`
  border: none;
  padding: 0;
  margin-top: 20px;
`;

const FormLabel = styled.label`
  display: block;
  font-size: 14px;
  color: #a3a6a8;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

const StyledTextField = styled(TextField)({
  background: 'none',
  color: 'rgb(202, 208, 216) !important',
  input: {
    color: 'black !important',
  },
  marginTop: '10px',
  paddingLeft: '4px',
  borderRadius: '8px',
  border: '1px solid #CAD0D8',
  ':not(:focus-within)': {
    boxShadow: 'none',
    input: {
      color: 'black !important',
    },
  },
});

const Description = styled(Text)({
  whiteSpace: 'pre-wrap',
});

const StyledSelectButton = styled(Button)({
  height: '40px',
  marginTop: '2px',
  marginBottom: '20px',
  border: '1px solid #FFFFFF',
  borderRadius: '8px',
  background: '#FFF',
  display: 'flex',
  justifyContent: 'space-between',
  color: 'rgba(255, 255, 255, 1)',
  fontSize: '14px',
  fontWeight: 400,
  padding: '0 13px',
  width: '100%',
  svg: {
    transition: 'transform 0.3s ease',
  },

  '&[aria-expanded="true"] svg': {
    transform: 'rotateZ(180deg)',
  }
});

const StyledSelect = styled(Select)({
  '&[aria-expanded="true"]': {
    // background: '#fff !important',
    color: 'black !important'
  },
  ':hover': {
    // background: '#fff !important',
    color: 'black !important'

  },
  div: {
    width: 'unset'
  }
})

const StyledTextareaField = styled(TextareaField)({
  background: 'none',
  color: 'black !important',
  marginTop: '10px',
  width: 'calc(calc(100%) - 18px)',
  padding: '8px',
  borderRadius: '8px',
  border: '1px solid #CAD0D8',
  ':not(:focus-within)': {
    boxShadow: 'none'
  },
});

const fieldType = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  SELECT: 'select',
};

const RenderSelect = ({ field }) => {
  const [value, setValue] = useState('');
  const { register, handleSubmit, errors, control } = useForm({ mode: 'all' });

  return (
    <div style={{ marginTop: '10px' }}>
      <Controller
        control={control}
        name="controlled-select-dropdown"
        as={
          <StyledSelect
            className="custom-action-builder-select"
            placement="bottom-end"
            value={value}
            onChange={setValue}
            content={
              <>
                {field.options &&
                  field.options.map((option) => (
                    <MenuRadio key={option.name} name={option.value}>
                      {option.name}
                    </MenuRadio>
                  ))}
              </>
            }
          >
            {({ value: v }) => (
              <StyledSelectButton
                iconAfter={<IconChevronDown size={16} color="#000" />}
                style={{ marginBottom: "16px", border: '1px solid #CAD0D8', color: '#000 !important' }}
              >
                {v ? <span style={{ color: '#fff !important' }}>{v}</span> : "Select a Value"}
              </StyledSelectButton>
            )}
          </StyledSelect>
        }
      />
    </div>
  );
};

class CustomActionPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [],
    };
  }

  onValueChange(field, value) {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        [field]: value,
      },
    });
  }

  renderTextInput(field) {
    const {
      fields: { [field.name]: text },
    } = this.state;

    return (
      <StyledTextField
        id={field.name}
        name={field.name}
        placeholder={field.placeholder || ''}
        value={text === undefined ? field.value : text}
        onChange={(e) => this.onValueChange(field.name, e.target.value)}
      />
    );
  }

  renderTextArea(field) {
    const {
      fields: { [field.name]: text },
    } = this.state;

    return (
      <StyledTextareaField
        row={2}
        rowsMin={4}
        rowsMax={8}
        id={field.name}
        name={field.name}
        placeholder={field.placeholder || ''}
        value={text === undefined ? field.value : text}
        onChange={(e) => this.onValueChange(field.name, e.target.value)}
      />
    );
  }

  renderSelectInput(field) {
    const {
      fields: { [field.name]: value },
    } = this.state;
    let text = '';

    if (value) {
      text = field.options.find((option) => option.value === value).name;
    }

    return (
      <div style={{ marginTop: '10px' }}>
        <Select
          value={text}
          content={
            <>
              {field.options &&
                field.options.map((option) => (
                  <MenuRadio key={option.name} name={option.value}>
                    {option.name}
                  </MenuRadio>
                ))}
            </>
          }
        >
          {({ value: v, ...rest }) => {
            console.log(v, rest);
            return <Button color="primary">{v || 'Select a value'}</Button>;
          }}
        </Select>
      </div>
    );
  }

  render() {
    const { title, description, fields } = this.props;
    const hasForm = fields.length > 0;

    return (
      <Box
        width="100%"
        padding="16"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          <Inline alignY="center" gapX="8">
            <IconCogFill size={24} color={theme.colors.palette.alertWarning300} />
            <Text variant="header" color="#000">
              {title}
            </Text>
          </Inline>
          <br />
          <Description color="black">{description}</Description>
          {hasForm &&
            fields.map((field) => {
              let el;
              switch (field.type) {
                case fieldType.TEXT:
                  el = this.renderTextInput(field);
                  break;
                case fieldType.TEXTAREA:
                  el = this.renderTextArea(field);
                  break;
                case fieldType.SELECT:
                  el = <RenderSelect field={field} />;
                  break;
                default:
                  el = null;
                  break;
              }
              return (
                <FormGroup key={field.name}>
                  <FormLabel>
                    {field.label || field.name}
                    {el}
                  </FormLabel>
                </FormGroup>
              );
            })}
        </Box>
        <Box marginTop="auto">
          <Button style={{ float: 'right' }} color="primary">
            Submit
          </Button>
          <Button
            color="primary"
            variant="ghost"
            style={{ marginRight: '10px', float: 'right' }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    );
  }
}

CustomActionPreview.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  fields: PropTypes.array,
};

CustomActionPreview.defaultProps = {
  title: '',
  description: '',
  fields: [],
};

export default CustomActionPreview;
