const actionType = {
  Create: 'Create',
  Read: 'Read',
  Update: 'Update',
  Delete: 'Delete',
};

const categories = [
  {
    type: 'offline',
    name: 'Offline',
  },
  {
    type: 'accounts',
    name: 'Accounts',
  },
  {
    type: 'teams',
    name: 'Teams',
  },
  {
    type: 'projects',
    name: 'Projects',
  },
  {
    type: 'assets',
    name: 'Assets',
  },
  {
    type: 'comments',
    name: 'Comments',
  },
  {
    type: 'reviewLinks',
    name: 'Review Links',
  },
  {
    type: 'webhooks',
    name: 'Webhooks',
  },
  {
    type: 'actions',
    name: 'Custom Actions',
  },
  {
    type: 'auditLogs',
    name: 'Audit Logs',
  },
  {
    type: 'presentations',
    name: 'Presentations',
  },
];

const baseScopes = {
  accounts: [
    {
      value: 'account.read',
      action: actionType.Read,
    },
  ],
  teams: [
    {
      value: 'team.create',
      action: actionType.Create,
    },
    {
      value: 'team.read',
      action: actionType.Read,
    },
    {
      value: 'team.update',
      action: actionType.Update,
    },
  ],
  projects: [
    {
      value: 'project.create',
      action: actionType.Create,
    },
    {
      value: 'project.read',
      action: actionType.Read,
    },
    {
      value: 'project.update',
      action: actionType.Update,
    },
    {
      value: 'project.delete',
      action: actionType.Delete,
    },
  ],
  assets: [
    {
      value: 'asset.create',
      action: actionType.Create,
    },
    {
      value: 'asset.read',
      action: actionType.Read,
    },
    {
      value: 'asset.update',
      action: actionType.Update,
    },
    {
      value: 'asset.delete',
      action: actionType.Delete,
    },
  ],
  comments: [
    {
      value: 'comment.create',
      action: actionType.Create,
    },
    {
      value: 'comment.read',
      action: actionType.Read,
    },
    {
      value: 'comment.update',
      action: actionType.Update,
    },
    {
      value: 'comment.delete',
      action: actionType.Delete,
    },
  ],
  reviewLinks: [
    {
      value: 'reviewlink.create',
      action: actionType.Create,
    },
    {
      value: 'reviewlink.read',
      action: actionType.Read,
    },
    {
      value: 'reviewlink.update',
      action: actionType.Update,
    },
    {
      value: 'reviewlink.delete',
      action: actionType.Delete,
    },
  ],
  presentations: [
    {
      value: 'presentation.create',
      action: actionType.Create,
    },
    {
      value: 'presentation.read',
      action: actionType.Read,
    },
    {
      value: 'presentation.update',
      action: actionType.Update,
    },
    {
      value: 'presentation.delete',
      action: actionType.Delete,
    },
  ],
};

const offlineScopes = [
  {
    value: 'offline',
    action: 'The offline scope is required to retrieve refresh tokens',
  },
];

const auditLogScopes = [
  {
    value: 'auditlog.read',
    action: actionType.Read,
  },
];

const userTokenScopes = {
  webhooks: [
    {
      value: 'webhook.create',
      action: actionType.Create,
    },
    {
      value: 'webhook.read',
      action: actionType.Read,
    },
    {
      value: 'webhook.update',
      action: actionType.Update,
    },
    {
      value: 'webhook.delete',
      action: actionType.Delete,
    },
  ],
  actions: [
    {
      value: 'action.create',
      action: actionType.Create,
    },
    {
      value: 'action.read',
      action: actionType.Read,
    },
    {
      value: 'action.update',
      action: actionType.Update,
    },
    {
      value: 'action.delete',
      action: actionType.Delete,
    },
  ],
};

// Builds the full object of scope metadata
export function buildScopeOptions({
  forAccountOwnerOrAdmin,
  forOAuth,
  forUserToken,
} = {}) {
  const result = {
    categories,
    scopes: baseScopes,
  };

  if (forAccountOwnerOrAdmin) {
    result.scopes = {
      ...result.scopes,
      auditLogs: auditLogScopes,
    };
  }

  if (forOAuth) {
    result.scopes = {
      ...result.scopes,
      ...userTokenScopes,
      auditLogs: auditLogScopes,
      offline: offlineScopes,
    };
  }

  if (forUserToken) {
    result.scopes = {
      ...result.scopes,
      ...userTokenScopes,
    };
  }

  return result;
}

// Takes the full object of scope metadata and transforms into an array of all scope strings
export function buildScopesList() {
  const { scopes } = buildScopeOptions({
    forAccountOwnerOrAdmin: true,
    forOAuth: true,
    forUserToken: true,
  });

  return Object.values(scopes).reduce(
    (result, category) => [...result, ...category.map(({ value }) => value)],
    []
  );
}

// Turns a list of scope strings from <ScopeSelect> into an object of all scopes,
// each with its enabled state
export function buildScopeParams(selectedScopes) {
  return buildScopesList().reduce(
    (result, scope) => ({
      ...result,
      [scope.replace('.', '_')]: selectedScopes.includes(scope),
    }),
    {}
  );
}

export function scopesToArray(scopes) {
  return Object.keys(scopes)
    .filter((scope) => scope !== 'id' && scopes[scope])
    .map((scope) => scope.replace('_', '.'));
}
