import React from 'react';
import * as Sentry from '@sentry/gatsby';
import { Router } from '@reach/router';
import { QueryClientProvider } from 'react-query';
import { AuthProvider } from '../context/auth';
import PrivateRoute from '../components/app/private-route';
import { queryClient } from '../api';
import Tokens from '../components/app/tokens/tokens';
import NewToken from '../components/app/tokens/new-token';
import EditToken from '../components/app/tokens/edit-token';
import Webhooks from '../components/app/webhooks/webhooks';
import NewWebhook from '../components/app/webhooks/new-webhook';
import EditWebhook from '../components/app/webhooks/edit-webhook';
import CustomActions from '../components/app/custom-actions/custom-actions';
import NewCustomAction from '../components/app/custom-actions/new-custom-action';
import EditCustomAction from '../components/app/custom-actions/edit-custom-action';
import OAuthApps from '../components/app/oauth-apps/oauth-apps';
import NewOAuthApp from '../components/app/oauth-apps/new-oauth-app';
import EditOAuthApp from '../components/app/oauth-apps/edit-oauth-app';
import WebhookLogs from '../components/app/webhooks/webhook-logs';
import CustomActionLogs from '../components/app/custom-actions/custom-action-logs';
import { ErrorFallback } from '../components/error-fallback';
import CustomActionBuilder from '../components/app/custom-actions/components/custom-action-builder';

const App = () => (
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
        <Router basepath="/app">
          <PrivateRoute path="/tokens" component={Tokens} />
          <PrivateRoute path="/tokens/new" component={NewToken} />
          <PrivateRoute path="/tokens/:tokenId/edit" component={EditToken} />
          <PrivateRoute path="/webhooks" component={Webhooks} />
          <PrivateRoute path="/webhooks/new" component={NewWebhook} />
          <PrivateRoute
            path="/webhooks/:webhookId/edit"
            component={EditWebhook}
          />
          <PrivateRoute
            path="/webhooks/:webhookId/logs"
            component={WebhookLogs}
          />
          <PrivateRoute path="/custom-actions" component={CustomActions} />
          <PrivateRoute
            path="/custom-actions/new"
            component={NewCustomAction}
          />
          <PrivateRoute
            path="/custom-actions/builder"
            component={CustomActionBuilder}
          />
          <PrivateRoute
            path="/custom-actions/:customActionId/edit"
            component={EditCustomAction}
          />
          <PrivateRoute
            path="/custom-actions/:customActionId/logs"
            component={CustomActionLogs}
          />
          <PrivateRoute path="/oauth-apps" component={OAuthApps} />
          <PrivateRoute path="/oauth-apps/new" component={NewOAuthApp} />
          <PrivateRoute
            path="/oauth-apps/:oauthAppId/edit"
            component={EditOAuthApp}
          />
        </Router>
      </Sentry.ErrorBoundary>
    </AuthProvider>
  </QueryClientProvider>
);

export default App;
