import React from 'react';
import { navigate } from 'gatsby';
import WebhookForm from './webhook-form';
import Layout from '../../layout';
import { useCreateWebhook } from '../../../api';
import Breadcrumbs from '../breadcrumbs';
import { FormContainer } from '../form';

const NewWebhook = () => {
  const createWebhook = useCreateWebhook();
  return (
    <Layout>
      <FormContainer>
        <Breadcrumbs
          currentPath="/app/webhooks/new"
          pages={[
            { path: '/app/webhooks', title: 'webhooks' },
            { path: '/app/webhooks/new', title: 'new' },
          ]}
        />
        <h2>Create a new webhook</h2>
        <WebhookForm
          onSubmit={async (formData) => {
            const newWebhook = await createWebhook.mutateAsync(formData);
            navigate('/app/webhooks', { state: { newWebhook } });
          }}
        />
      </FormContainer>
    </Layout>
  );
};
export default NewWebhook;
