import * as React from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import { useToken, useUpdateToken } from '../../../api';
import Layout from '../../layout';
import TokenForm from './token-form';
import Breadcrumbs from '../breadcrumbs';
import { FormContainer } from '../form';

const EditToken = ({ tokenId }) => {
  const { data } = useToken(tokenId);
  const updateToken = useUpdateToken(tokenId);

  const path = `/app/tokens/${tokenId}/edit`;

  return (
    <Layout>
      <FormContainer>
        <Breadcrumbs
          currentPath={path}
          pages={[
            { path: '/app/tokens', title: 'tokens' },
            { path, title: 'edit' },
          ]}
        />
        <h2>Edit Token</h2>
        {data && (
          <TokenForm
            onSubmit={async (formData) => {
              await updateToken.mutateAsync(formData);
              navigate('/app/tokens');
            }}
            initialValues={{ description: data.description }}
            editing
          />
        )}
      </FormContainer>
    </Layout>
  );
};

export default EditToken;

EditToken.propTypes = {
  tokenId: PropTypes.string.isRequired,
};
