import axios from 'axios';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import { Button, Inline, Text, Spinner, theme } from '@frameio/vapor';
import React, { useState, Fragment } from 'react';
import { IconUploads } from '@frameio/vapor-icons';
import styled from 'styled-components';
import {
  useOAuthApps,
  useDeleteOAuthApp,
  instance,
  queryClient,
} from '../../../api';
import Layout from '../../layout';
import SEO from '../../seo';

import {
  Listing,
  Links,
  Overview,
  Items,
  Item,
  ItemTitle,
  ItemHeader,
  ItemButtons,
  ItemEditButton,
  ItemTrashButton,
  Field,
  FieldKey,
  FieldValue,
  Scopes,
} from '../listing';

import { formatDate } from '../../../utils';
import { SecretField } from '../form/textfield';
import ConfirmDelete from '../confirm-delete';

const MarginWrapper = styled.div({
  paddingLeft: 'var(--page-margin)',
  paddingRight: 'var(--page-margin)',
});

const Wrapper = styled.div`
  width: 35px;
  height: 35px;
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #e3e6ec;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    border: 1px solid ${theme.colors.palette.blue400};
  }
`;

const IconWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OauthAppImage = ({ imageUrl, oauthAppId }) => {
  const [image, setImage] = useState(imageUrl);
  const [uploading, setUploading] = useState(false);
  const hiddenFileInput = React.useRef(null);

  const uploadImage = (url, file) => {
    const options = {
      headers: {
        'Content-Type': file.type,
        'x-amz-acl': 'private',
      },
    };
    return axios.put(url, file, options);
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    setUploading(true);

    instance
      .patch(`/oauth_apps/${oauthAppId}`, {
        image: { type: 'image/png', uploaded: true },
      })
      .then(({ data: oauthApp }) => {
        uploadImage(oauthApp.upload_url, file).then(() => {
          axios.get(oauthApp.image).then(() => {
            queryClient.invalidateQueries('oauth-apps');
            setImage(oauthApp.image);
            setUploading(false);
          });
        });
      });
  };

  return (
    <Wrapper center onClick={() => hiddenFileInput.current.click()}>
      {uploading && <Spinner size="small" isLoading={uploading} />}
      <input
        type="file"
        value=""
        style={{ display: 'none' }}
        ref={hiddenFileInput}
        onChange={handleChange}
      />
      {!image && !uploading && (
        <IconWrapper>
          <IconUploads height="20px" />
        </IconWrapper>
      )}
      {image && !uploading && <img alt="oauth" width="100%" src={image} />}
    </Wrapper>
  );
};

OauthAppImage.propTypes = {
  oauthAppId: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
};

OauthAppImage.defaultProps = {
  imageUrl: null,
};

const OAuthApps = ({ location }) => {
  const [copied, setCopied] = useState(undefined);
  const { data: oauthApps } = useOAuthApps();

  const deleteOAuthApp = useDeleteOAuthApp();
  const [toDelete, setToDelete] = useState(null);

  const copySecret = (value) => {
    copy(value);
    setCopied(value);
    setTimeout(() => setCopied(undefined), 3000);
  };

  const newOAuthApp = location.state?.newOAuthApp;

  return (
    <Layout>
      <SEO
        title="Frame.io - Developer - Webhooks"
        description="Learn more about building with the Frame.io API using Developer Tokens, Webhooks, Custom Actions, and OAuth apps. "
      />
      <MarginWrapper>
        <Listing>
          <h1>OAuth Apps</h1>
          <Text variant="header" color="#000000">
            Create OAuth 2.0 Apps.
          </Text>
          <Overview>
            <Text>
              Frame.io supports OAuth2.0 for apps. Register with a set of
              required scopes, and Frame.io users will be able to log in, grant
              access, and extend their workflows through your app.
            </Text>
            <Links>
              <Link to="/app/oauth-apps/new">
                <Button color="primary">Create an OAuth App</Button>
              </Link>
              <Link to="/docs/oauth-2-applications/oauth-2-code-authorization-flow">
                <Button
                  rel="noopener noreferrer"
                  target="_blank"
                  variant="ghost"
                  color="primary"
                >
                  See Docs
                </Button>
              </Link>
            </Links>
          </Overview>
          <Items>
            {oauthApps?.map((o) => (
              <Item
                key={`custom-action-${o.id}`}
                className={newOAuthApp && newOAuthApp.id === o.id && 'new-item'}
              >
                <ItemHeader>
                  <OauthAppImage
                    uploadUrl={o.upload_url}
                    oauthAppId={o.id}
                    imageUrl={o.image}
                  />
                  <ItemTitle variant="header">{o.name}</ItemTitle>
                  <ItemButtons>
                    <ItemEditButton path={`/app/oauth-apps/${o.id}/edit`} />
                    <ItemTrashButton onClick={() => setToDelete(o)} />
                  </ItemButtons>
                </ItemHeader>
                <Field>
                  <FieldKey>Client ID</FieldKey>
                  <FieldValue>
                    <Inline gap={8}>
                      <SecretField size="small" value={o.client_id} />
                      <Button
                        style={{ height: '26px' }}
                        variant="ghost"
                        color="primary"
                        size="small"
                        onClick={() => copySecret(o.client_id)}
                      >
                        {copied === o.client_id ? 'Copied!' : 'Copy'}
                      </Button>
                    </Inline>
                  </FieldValue>
                </Field>
                <Field>
                  <FieldKey>Client Secret</FieldKey>
                  <FieldValue>
                    <Inline gap={8}>
                      <SecretField size="small" value={o.client_secret} />
                      <Button
                        style={{ height: '26px' }}
                        variant="ghost"
                        color="primary"
                        size="small"
                        onClick={() => copySecret(o.client_secret)}
                      >
                        {copied === o.client_secret ? 'Copied!' : 'Copy'}
                      </Button>
                    </Inline>
                  </FieldValue>
                </Field>
                <Field>
                  <FieldKey>Redirect URIs</FieldKey>
                  <FieldValue>{o.redirect_uris.join(', ')}</FieldValue>
                </Field>
                <Field>
                  <FieldKey>Scopes</FieldKey>
                  <FieldValue style={{ marginLeft: '16px' }}>
                    <Scopes scopes={o.scopes} />
                  </FieldValue>
                </Field>
                <Field>
                  <FieldKey>Uses PKCE</FieldKey>
                  <FieldValue>
                    {o.token_endpoint_auth_method === 'none' ? 'True' : 'False'}
                  </FieldValue>
                </Field>
                <Field>
                  <FieldKey>Created</FieldKey>
                  <FieldValue>{formatDate(o.inserted_at)}</FieldValue>
                </Field>
              </Item>
            ))}
          </Items>
        </Listing>
        {!!toDelete && (
          <ConfirmDelete
            isShowing={!!toDelete}
            title="Delete OAuth App?"
            name={toDelete.name}
            onCancel={() => setToDelete(null)}
            onConfirm={() => {
              deleteOAuthApp
                .mutateAsync(toDelete.id)
                .then(() => setToDelete(null));
            }}
          />
        )}
      </MarginWrapper>
    </Layout>
  );
};
export default OAuthApps;

OAuthApps.propTypes = {
  location: PropTypes.object.isRequired,
};
