import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { AlertDialog, AlertDialogButton } from '@frameio/vapor';

const ConfirmDelete = ({ isShowing, name, title, onCancel, onConfirm }) => {
  const leastDestructiveRef = useRef(null);
  return (
    <AlertDialog
      isShowing={isShowing}
      variant="warning"
      title={<span style={{ color: 'rgb(32, 34, 43)' }}>{title}</span>}
      details={
        <div>
          <br />
          Are you sure want to delete{' '}
          <span style={{ fontWeight: 500 }}>{name}</span>
          ?
          <br />
          <br />
          This action is permanent.
          <br />
        </div>
      }
      actions={
        <>
          <AlertDialogButton onClick={onCancel} ref={leastDestructiveRef}>
            Dismiss
          </AlertDialogButton>
          <AlertDialogButton onClick={onConfirm} color="primary">
            Delete
          </AlertDialogButton>
        </>
      }
    />
  );
};

export default ConfirmDelete;

ConfirmDelete.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
