import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import { Spinner } from '@frameio/vapor';
import Layout, { HorizontalWrapper } from '../../layout';
import { useCustomActionLogs } from '../../../api';
import Pagination from '../pagination';
import LogTable from '../log-table';
import JSONDialog from '../json-dialog';
import Breadcrumbs from '../breadcrumbs';
import { Listing } from '../listing';

const PaginationWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

const CustomActionLogs = ({ customActionId }) => {
  const [page, setPage] = useState(1);
  const { data, isLoading, isFetching } = useCustomActionLogs(
    customActionId,
    page
  );
  const [dialogData, setDialogData] = useState(null);

  const customActionLogs = data?.customActionLogs || [];
  const pagination = data?.pagination;

  const dialogShowing = dialogData !== null && !!customActionLogs.length;
  const path = `/app/custom-actions/${customActionId}/logs`;

  return (
    <Layout>
      <HorizontalWrapper>
        <Listing className="action-logs">
          <Breadcrumbs
            currentPath={path}
            pages={[
              { path: '/app/custom-actions', title: 'custom actions' },
              { path, title: 'logs' },
            ]}
          />
          <h2>Custom Action Logs</h2>
          <LogTable
            data={customActionLogs}
            isLoading={isLoading}
            onRequestView={(index) => setDialogData({ index, type: 'request' })}
            onResponseView={(index) => setDialogData({ index, type: 'response' })}
            emptyMessage="No custom action logs found."
          />
          {!isLoading && pagination ? (
            <PaginationWrapper>
              <Pagination
                page={pagination.page}
                onPageChange={setPage}
                pages={pagination.pages}
                isLoading={isFetching && !isLoading}
              />
            </PaginationWrapper>
          ) : null}
          {dialogShowing && (
            <JSONDialog
              isShowing={dialogShowing}
              onClose={() => setDialogData(null)}
              title={`${capitalize(dialogData.type)} Body`}
              data={customActionLogs[dialogData.index][`${dialogData.type}_body`]}
            />
          )}
          <Spinner size="small" isLoading={isLoading} />
        </Listing>
      </HorizontalWrapper>
    </Layout>
  );
};

CustomActionLogs.propTypes = {
  customActionId: PropTypes.string.isRequired,
};

export default CustomActionLogs;
