import React, { useEffect, createContext, useContext } from 'react';
import { Box, Spinner, Text } from '@frameio/vapor';
import PropTypes from 'prop-types';
import {
  identify,
  inferKeys,
  getAuthToken,
} from '@frameio/developer-components';
import { useCurrentUser } from '../api';
import storage from '../utils/storage';

export const AuthContext = createContext({});

export function useAuthContext() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const token = getAuthToken();
  const { data: user, status, error } = useCurrentUser({ enabled: !!token });
  const { authTokenKey } = inferKeys();

  useEffect(() => {
    if (status === 'error') {
      storage.remove(authTokenKey);
    }
  }, [status]);

  useEffect(() => {
    if (user) {
      identify(user.id, {});
    }
  }, [user]);

  if (status === 'loading' && !error) {
    return (
      <Box
        height="100vh"
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Spinner size="small" />
        <Text style={{ margin: '10px' }} color="brandPrimary">
          Loading auth...
        </Text>
      </Box>
    );
  }

  const auth = {
    user,
  };

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
