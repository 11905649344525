import { PasswordField, TextField as VTextField } from '@frameio/vapor';
import styled from 'styled-components';
import breakpoints from '../../../utils/breakpoints';

const base = {
  border: '1px solid rgb(227, 230, 236)',

  background: '#FFFFFF',
  'input::placeholder': {
    color: 'rgb(125, 130, 156)',
    fontSize: '13px',
  },
  ':not(:focus-within)': {
    boxShadow: 'none',
  },
  input: {
    color: 'rgb(32, 34, 43)',
  },
};
export const TextField = styled(VTextField)(base);

export const SecretField = styled(PasswordField)({
  ...base,
  width: '100%',
  [breakpoints.sm]: {
    width: '300px',
  },
});
