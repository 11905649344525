import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import { useId } from '@reach/auto-id';
import { Button, Switch, Text } from '@frameio/vapor';
import { buildScopeOptions, buildScopeParams } from '../../../utils/scopes';
import { Fieldset, Label, ScopeSelect, TextField, useForm } from '../form';

const defaultValues = {
  name: '',
  redirect_uris: '',
  pkce: false,
  scopes: [],
};

export function buildRedirectParams(redirectUriString) {
  return redirectUriString.split(',').reduce((result, uri) => {
    const trimmed = uri.trim();
    if (trimmed) {
      result.push(trimmed);
    }
    return result;
  }, []);
}

const OAuthAppsForm = ({
  onSubmit,
  initialValues,
  isAdminOrOwner,
  canTogglePKCE,
}) => {
  const scopeOptions = buildScopeOptions({
    forAccountOwnerOrAdmin: isAdminOrOwner,
    forOAuth: true,
  });

  const { errors, values, onChange, onBlur } = useForm({
    ...defaultValues,
    ...initialValues,
  });

  return (
    <form>
      <Fieldset>
        <Label htmlFor={useId('oauth-app-name')}>Name</Label>
        <TextField
          id="oauth-app-name"
          name="name"
          placeholder="My Awesome App"
          value={values.name}
          onChange={(e) => onChange({ name: e.target.value })}
          onBlur={() => onBlur('name')}
          error={errors.name}
          data-oauth-app-name
        />
      </Fieldset>
      <Fieldset>
        <Label htmlFor={useId('oauth-app-redirect_uris')}>Redirect URIs</Label>
        <TextField
          id="oauth-app-redirect_uris"
          name="redirect_uris"
          placeholder="https://something.com/oauth/callback"
          value={values.redirect_uris}
          onChange={(e) => onChange({ redirect_uris: e.target.value })}
          onBlur={() => onBlur('redirect_uris')}
          error={errors.redirect_uris}
          data-oauth-app-redirect_uris
        />
      </Fieldset>

      {canTogglePKCE && (
        <Fieldset>
          <Label htmlFor="oauth-app-pkce">Uses PKCE</Label>
          <Text color="rgb(112, 117, 146)">
            Toggle this form to use Proof Key for Code Exchange (PKCE), a method
            for public clients to use the Authorization Code grant flow without
            the need to store a static client secret value. Learn more about
            PKCE{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://oauth.net/2/pkce/"
            >
              here
            </a>
            .
          </Text>
          <br />
          <Switch
            id="oauth-app-pkce"
            checked={values.pkce}
            onChange={() => onChange({ pkce: !values.pkce })}
            data-oauth-app-pkce
          />
        </Fieldset>
      )}

      <Fieldset>
        <ScopeSelect
          id="oauth-app-scopes"
          value={values.scopes}
          onChange={(scopes) => onChange({ scopes })}
          onBlur={() => onBlur('scopes')}
          options={scopeOptions}
          error={errors.scopes}
          data-oauth-app-scopes
        />
      </Fieldset>

      <Fieldset>
        <Link
          to="/app/oauth-apps"
          style={{ marginRight: '10px', textDecoration: 'none' }}
        >
          <Button type="reset" variant="ghost" color="primary">
            Cancel
          </Button>
        </Link>
        <Button
          type="submit"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            const { name } = values;
            return onSubmit({
              name,
              redirect_uris: buildRedirectParams(values.redirect_uris),
              token_endpoint_auth_method: values.pkce
                ? 'none'
                : 'client_secret_basic',
              scopes: buildScopeParams(values.scopes),
            });
          }}
        >
          Submit
        </Button>
      </Fieldset>
    </form>
  );
};

export default OAuthAppsForm;

OAuthAppsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  isAdminOrOwner: PropTypes.bool.isRequired,
  canTogglePKCE: PropTypes.bool.isRequired,
};

OAuthAppsForm.defaultProps = {
  initialValues: defaultValues,
};
